import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
    _lineTheme,
    PredictionZone,
    getTimeFormatTitle,
    getTimeFormat,
    getPositionForTitle,
    MemoAreaLayerRed,
    ActiveLine,
    EmptyZoneMesh
} from "./index";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {useParams} from "react-router";
import {disabledRedZone} from "../../utils/constants";

const showTooltip = (text) => {
    const tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
}

const hideTooltip = () => {
    const tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
}


const MemoLineChartContent = ({
      data, rangeType, dateType, rules,
      setXScale, setYScale, isPrediction = false, t,
      activeLinePosition, setActiveLine, activeLineIsNull = true, propertyId, from
    }) => useMemo(() => {
    const markers = data.length ? [
        {
            ...markerStyle,
            value: getPositionForTitle(data, 0),
            legend: data[0] ? data[0].title : '',
        },
        {
            ...markerStyle,
            value: getPositionForTitle(data, 1),
            legend: data[1] ? data[1].title : '',
        }
    ] : []

    const todayMarker = {
            axis: "x",
            value: new Date(moment().hour(0).format('MM/DD/YYYY')),
            lineStyle: {
                stroke: "#0000FF",
                strokeWidth: 2,
                opacity: isPrediction ? 1 : 0
            },
            legend: t('lineChart.today'),
            textStyle: {
                transform: 'translate(-20px, -20px) rotate(0deg)',
                fill: '#240bff',
                opacity: isPrediction ? 1 : 0
            }
        }
    const getMarkers = data.length > 1 && data[0].data.length
        ? [...markers, todayMarker]
        : [...(data.length && data[0].data.length ? [markers[0]] : []), todayMarker];
    return (
        <ResponsiveLine
            data={data}
            theme={_lineTheme}
            margin={{ top: 35, right: 120, bottom: 60, left: 68 }}
            colors={{ datum: 'color' }}
            layers={[
                "grid",
                p => <PredictionZone {...p} isPrediction={isPrediction} t={t} />,
                "markers",
                ...data.map((d, i) =>
                    (p) => <MemoAreaLayerRed
                        {...p} rule={rules[i]} index={i} setXScale={setXScale}
                        setYScale={setYScale} activeLineIsNull={activeLineIsNull} setActiveLine={setActiveLine} propertyId={propertyId}
                    />
                ),
                EmptyZoneMesh,
                "areas",
                "lines",
                "slices",
                "axes",
                "points",
                'crosshair',
                () => <ActiveLine activeLinePosition={activeLinePosition} />,
                'mesh',
            ]}
            sliceTooltip={d => {
                setActiveLine(d)
                return null
            }}
            markers={getMarkers}
            xFormat="time:%Y-%m-%d"
            xScale={{
                type: 'time',
                format: '%Y-%m-%d %H:%M',
                useUTC: false,
                precision: rangeType === 1 ? 'hour' : 'day',
            }}
            yFormat=" >-.1f"
            yScale={{
                type: 'linear',
                stacked: false,
                reverse: false,
                min: 'auto',
                max: 'auto',
            }}
            curve="catmullRom"
            axisTop={{
                format: t => getTimeFormatTitle(t, rangeType, dateType, from),
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
            }}
            axisBottom={{
                format: t => getTimeFormat(t, rangeType, dateType),
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                tickValues: dateType === 'month' ?  `every ${window.innerWidth < 1500 ? 2 : 1} days` : undefined,
            }}
            axisLeft={{
                format: value => `${value} °C`,
                orient: 'left',
                tickSize: 0,
                tickPadding: 18,
                tickRotation: 0,
            }}
            axisRight={null}
            enableGridX={false}
            lineWidth={1}
            enablePoints={false}
            areaOpacity={0.05}
            enableSlices="x"
            crosshairType="bottom"
            useMesh={true}
            motionConfig="stiff"
        />
    )
}, [
    data, rangeType, dateType, rules, setXScale, setYScale, isPrediction,
    t, activeLinePosition.x, activeLinePosition.y, activeLineIsNull, setActiveLine, propertyId
])

const markerStyle = {
    axis: 'y',
    lineStyle: { strokeWidth: 0 },
    textStyle: {
        fill: '#00205B',
        fontWeight: 400,
        fontSize: 16,
        transform: 'translateX(calc(97% - 145px))',
        textAnchor: 'inherit',
    },
}

const LineChart = props => {
    const name = 'lineChart';
    const { data, rangeType, setActive, dateType, rules, isPrediction, from } = props;
    const [xScale, setXScale] = useState(null);
    const [yScale, setYScale] = useState(null);
    const [activeLine, setActiveLine] = useState(null);
    const defMousePositionOnSVG = { x: 0, y: 0 };
    const [mousePositionOnSVG, setMousePositionOnSVG] = useState(defMousePositionOnSVG);
    const { id: _propertyId } = useParams();
    const { t } = useTranslation();
    useEffect(() => {
        if (data) setActiveLine(null)
    }, [data]);

    const TooltipForRedArea = useCallback(({ data, xScale, yScale, rule, mousePositionOnSVG, modbusId }) => {
        if (!data || !rule || (disabledRedZone[_propertyId] && disabledRedZone[_propertyId].includes(modbusId))) return null;
        
        const { x = 0, y = 0, width } = mousePositionOnSVG;
        const minValue = rule.max || 100;
        const heightLine = width / (data.length - 1)  || 1
        const activeColumn = data.find((d, i) => {
            if (
                d.y > minValue
                && xScale(new Date(d.x)) <= x && x <= xScale(new Date(d.x)) + (heightLine / (i === 0 ? 2 : 1))
                && yScale(d.y) < y && yScale(minValue) > y
            ) return { d, i };
        })
        if (activeColumn) {
            showTooltip(t(`${name}.ExcessiveEnergyConsumption`));
        } else hideTooltip();
    }, [xScale, yScale, mousePositionOnSVG, t]);

    useEffect(() => {
        if (activeLine) setActive(activeLine)
    }, [activeLine])

    useEffect(() => {
        if (xScale && yScale) {
            data.map((d, i) => TooltipForRedArea({ data: d.data, xScale, yScale, rule: rules[i], mousePositionOnSVG, modbusId: parseInt(d.id) }));
        }
    }, [xScale, yScale, mousePositionOnSVG])

    const getPointOnChart = useCallback(e => {
        const { clientX, clientY } = e;
        const { left, top, width } = e.currentTarget.querySelector('svg g g')?.getBoundingClientRect();
        setMousePositionOnSVG({ x: clientX - left, y: clientY - top, width })

        let tooltip = document.getElementById("tooltip");
        tooltip.style.left = clientX - left + 'px';
        tooltip.style.top = clientY - top + 'px';
    }, [setMousePositionOnSVG]);

    const setXScaleFunc = useCallback(xS => setXScale(() => xS), [setXScale]);
    const setYScaleFunc = useCallback(yS => setYScale(() => yS), [setYScale]);

    const activeLinePosition = {x: activeLine?.slice.x, y: activeLine?.slice.height};

    return (
        <div className="h-100 position-relative nivo-line-chart-points"
            onMouseMove={getPointOnChart}
            onMouseLeave={() => setMousePositionOnSVG(defMousePositionOnSVG)}
        >
            <MemoLineChartContent
                data={data}
                rangeType={rangeType}
                dateType={dateType}
                rules={rules}
                setXScale={setXScaleFunc}
                setYScale={setYScaleFunc}
                isPrediction={isPrediction}
                t={t}
                activeLinePosition={activeLinePosition}
                activeLineIsNull={activeLine === null}
                setActiveLine={setActiveLine}
                propertyId={_propertyId}
                from={from}
            />
        <div id="tooltip" className="pointer-event-none line-chart-tooltip-info" />
    </div>
    )
};

export default LineChart;