import React from 'react';
import {TableCell, TableRow} from "@mui/material";
import { exceptionsTemperatureRuleNotModal, sensorListRule } from "../../utils/constants";
import styles from '../../styles/components/Sencors/SensorList.module.css'
import {useParams} from "react-router";
import moment from "moment";
import {DenseTable, TooltipWrap} from "../index";
import {useTranslation} from "react-i18next";
import {getNameSensor} from "../../utils/functions";

const getTemperature = (row = {}, type, t, exception = [],) => {
  const sensor = row.sensors.find(s => s.type === type)
  const { _id: id, updated_at, temperature, status = {} } = sensor || {};
  const time = updated_at ? moment(updated_at).format(t('time.format')) : null

  return exception.includes(row.modbus) && !sensor
      ? null
      : (
          <TooltipWrap id={id} date={time}>
              <div className={status.status ? '' : styles.error_status}>
                  {`${sensor ? temperature.toFixed(0) : '--'}°C`}
              </div>
          </TooltipWrap>
      )
}

const SensorList = props => {
  const { cards = [] } = props;
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const { t } = useTranslation();

  const exception = exceptionsTemperatureRuleNotModal[id];

  const getName = row => {
      const nameObj = getNameSensor(row, t, id);
      return `${nameObj.component} ${nameObj.name}`;
  }

  const TrinkwasserList = cards.filter(c => sensorListRule['Trinkwasser'].includes(c.modbus))
  const HeizungList = cards.filter(c => sensorListRule['Heizung'].includes(c.modbus))

  return (
      <div className={`${styles.tables_wrap} mt-1`}>
          <DenseTable header={[
              {text: t('Sensors.table.Potable'), align: 'left', style: {fontSize: '18px'}},
              {text: 'TECHEM'},
              {text: 'PAUL'},
          ]}>
              {TrinkwasserList.map((row = {}) => (
                  <TableRow
                      key={row.modbus}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell align="left">{getName(row)}</TableCell>
                      <TableCell align="center">{getTemperature(row, 'Techem', t, exception)}</TableCell>
                      <TableCell align="center">{getTemperature(row, 'PAUL', t, exception)}</TableCell>
                  </TableRow>
              ))}
          </DenseTable>
          <DenseTable header={[
              {text: t('Sensors.table.Heater'), align: 'left', style: {fontSize: '18px'}},
              {text: 'TECHEM'},
              {text: 'PAUL'},
          ]}>
              {HeizungList.map((row = {}) => (
                  <TableRow
                      key={row.modbus}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell align="left">{getName(row)}</TableCell>
                      <TableCell align="center">{getTemperature(row, 'Techem', t)}</TableCell>
                      <TableCell align="center">{getTemperature(row, 'PAUL', t)}</TableCell>
                  </TableRow>
              ))}
          </DenseTable>
      </div>
  )
};

export default SensorList;