import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Sencors/SensorCard.module.css'
import {Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";
import { TemperatureCircle, StartFlushingForPipes, SensorCardFlushingInfo } from "../index";
import {getNameSensor} from "../../utils/functions";
import {exceptionsTemperatureRule, exceptionsTemperatureRuleNotModal, modbusType} from "../../utils/constants";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getDemoVersion} from "../../redux/selectors";
import {useTranslation} from "react-i18next";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/components/Sencors/FlushingSlider.css";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const SensorCard = props => {
  const defaultTabType = 'Techem'
  const { card = {}, isModal = false, warningFilter = false } = props;
  const { id: _propertyId } = useParams();
  const propertyId = _propertyId.split('&')[0];
  const { t } = useTranslation();
  const [tabType, setTabType] = useState(defaultTabType);
  const demoVersion = useSelector(getDemoVersion);
  let timer = null;
  const modbusesForFlushing = [160, 161, 162];
  

  const hideTime = e => {
    const timeBox = e.target.querySelectorAll('.time-box');
    if (timeBox) {
      timer = setTimeout(() => timeBox.forEach(t => t.classList.remove('time-box-for-hover')), 500);
    }
  };
  const showTime = e => {
    const timeBox = e.target.querySelectorAll('.time-box');
    if (timeBox) {
      clearTimeout(timer);
      timeBox.forEach(t => t.classList.add('time-box-for-hover'));
    }
  };

  const { sensors = [], modbus } = card || {};
  const { _id } = sensors[0] || {};

  const exception = modbus
      ? !isModal
        ? exceptionsTemperatureRuleNotModal[propertyId]?.includes(modbus)
        : exceptionsTemperatureRule[propertyId]?.includes(modbus)
      : false;

  const sensorName = sensors[0] ? getNameSensor(sensors[0] ? sensors[0] : null, t) || {} : {};

  useEffect(() => {
    if (warningFilter) {
      if (sensors[0].status !== null) {
        const statuses = sensors.map(sensor => !sensor.status.status);
        if (statuses.includes(false)) {
          const index = statuses.indexOf(true);
          setTabType( index !== -1 ? sensors[index].type : defaultTabType);
        } else {
          setTabType(defaultTabType);
        }
      } else {
        setTabType(sensors[0].type);
      }
    } else {
      setTabType(defaultTabType);
    }
  }, [warningFilter, sensors])

  return(
    <div className={`${styles.container} ${isModal ? styles.isModal : ''}  sensor-card`} key={_id} onMouseEnter={showTime} onMouseLeave={hideTime}>
      <div className={styles.name_wrap}>
        <div className={`${isModal ? styles.draggable_place : ''} draggable-place`} />
        <p className={styles.name}>{sensors[0] ? sensorName.component : ''}</p>
        <p className={styles.name}>{sensors[0] ? sensorName.name : ''}</p>
      </div>
      <div>
        {exception ? (
          <Tabs
              transition={false}
              activeKey={sensors[0].type}
              onSelect={(k) => setTabType(k)}
              className={`tabs mb-3 ${demoVersion ? 'disable_tab' : ''}`}
          >
            <Tab eventKey={sensors[0].type} title={sensors[0].type} className="tab"/>
          </Tabs>
        ) : (
            <Tabs
                transition={false}
                activeKey={tabType}
                onSelect={(k) => setTabType(k)}
                className={`tabs mb-3 ${demoVersion ? 'disable_tab' : ''}`}
            >
              {
                modbusType[propertyId].map(mType =>
                    <Tab key={mType} eventKey={mType} title={mType} className="tab"/>
                )
              }
            </Tabs>
        )}
        {propertyId !== "62061f10452e21129b8ca241" && modbusesForFlushing.includes(card.modbus) ? 
          (
            <Slider {...settings}>
              <TemperatureCircle sensors={sensors} type={exception ? sensors[0].type : tabType} />
              <StartFlushingForPipes temperature={sensors[0].temperature}/>
              <SensorCardFlushingInfo sensors={sensors}/>
            </Slider>
          ) : (
            <div className={styles.temperatureCircleContainer}>
              <TemperatureCircle sensors={sensors} type={exception ? sensors[0].type : tabType} />
            </div>
          )
        }
      </div>
    </div>
  )
};

export default SensorCard;
