import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Modal, InformationDetails, DetailsCharts, ImagesDetails, InformationFlushingProcess } from "../index";
import {Tab} from "bootstrap";
import {Tabs} from "react-bootstrap";
import styles from "../../styles/components/DetailsCard/DetailsCard.module.css";
import {useTranslation} from "react-i18next";
import {modbusType, temperatureRule} from "../../utils/constants";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getDemoVersion} from "../../redux/selectors";
import {getFromDay} from "../../utils/functions";
import moment from "moment";

const EmptyTab = () => (<div/>)

const DetailsCard = props => {
    const name = 'DetailsCard';
    const { handleCloseDetails, open, card } = props;
    const { modbus = {}, details = {} } = card || {};
    const { tabs = [] } = details;
    const [tabType, setTabType] = useState(tabs[0] || 'info')
    const [ isOpen, setIsOpen] = useState(false);
    const [ rules, setRules] = useState(null);
    const { t } = useTranslation();
    const { id: _propertyId } = useParams();
    const [dateType, setDateType] = useState('day');
    const [from, setFrom] = useState(getFromDay(moment()));
    const [to, setTo] = useState(moment());
    const [rangeType, setRangeType] = useState(1);
    const demoVersion = useSelector(getDemoVersion);
    const isExistTechem = modbusType[_propertyId].includes('Techem');
    const isExistPaul = modbusType[_propertyId].includes('PAUL');

    useEffect(() => {
        if (modbus) {
            const modbusList = typeof modbus === 'number' ? [modbus] : Object.values(modbus);
            const rulesList = temperatureRule[_propertyId];
            setRules(modbusList.map(m => rulesList.find(r => r.modbus.includes(m))))
        }
    }, [modbus, _propertyId]);
    const handleOpen = (v) => setIsOpen(v);
    const getTab = useCallback(tab => {
        const dateFilter = { dateType, from, to, rangeType, setDateType, setFrom, setTo, setRangeType }
        switch (tab) {
            case 'images': return { title: t(`${name}.images`), Content: ImagesDetails, _props: { openFullSize: handleOpen }};
            case 'info': return { title: t(`${name}.Information`), Content: InformationDetails, _props: { openFullSize: handleOpen }};
            case 'live_date_paul': return { title: !demoVersion ? t(`${name}.LiveDataPaul`) : t(`${name}.DemoVersion`), Content: DetailsCharts, _props: { type: 'PAUL', rules, ...dateFilter }};
            case 'live_date_techem': return { title: !demoVersion ? t(`${name}.LiveDataTechem`) : t(`${name}.DemoVersion`), Content: DetailsCharts, _props: { type: 'Techem', rules, ...dateFilter }};
            case 'washing_process': return { title: t(`${name}.WashingProcess`), Content: InformationFlushingProcess, _props: { type: 'Techem' }};
            default : return null;
        }
    }, [t, rules, dateType, from, to, rangeType, setDateType, setFrom, setTo, setRangeType ]);
    const getTabRender = useCallback(tab => {
        const tabContent = getTab(tab);
        if (!tabContent || (!isExistPaul && tab === 'live_date_paul')) return null;
        const { title, Content, _props, disabled = false } = tabContent;
        return (
            <Tab eventKey={tab} title={title} className="tab" key={tab} disabled={disabled} >
                <Content card={card} {..._props} />
            </Tab>
        )
    }, [card, getTab]);
    return useMemo(() => (
        <Modal boxId={'details_card_modal'}
               handleCloseDetails={!isOpen ? handleCloseDetails : null}
               open={open}
               header={t(`houseCards.${card.textType}`)}
        >
            <div>
                <Tabs
                    transition={false}
                    activeKey={tabType}
                    onSelect={setTabType}
                    className={`${styles.tabs_content} tabs detail_card mb-3`}
                >
                    {tabs.map(getTabRender)}
                </Tabs>
            </div>
        </Modal>
    ), [isOpen, tabs, tabType, open, handleCloseDetails, setTabType, getTabRender, t, card.textType]);
}

export default DetailsCard;