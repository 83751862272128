import React, {useContext, useEffect} from 'react';
import {
  Img360View,
  PerformanceImgType12,
  PerformanceImgType18,
  PerformanceImgTypeFlushing,
  PerformanceImgTypePrototype,
  FilterPerformanceImg,
  Sensors,
  HousesList,
  PipesDetails,
  Footer
} from './index'
import {Col, Container, Row} from "react-bootstrap";
import styles from '../styles/components/DetailView.module.css'
import PropertyInform from "./PropertyInform";
import {useDispatch, useSelector} from "react-redux";
import {
  setActiveProperty,
  setZoom,
  setPitch,
  resetFilterPerformance
} from "../redux/actions";
import {getAuth, getDataProperties, getDataProperty, getPrototypeMode} from "../redux/selectors";
import {SocketContext} from "../utils/socket-config";
import {useParams} from "react-router";
import {useDocumentTitle} from "../hooks";
import { BackButton } from "./Inputs";
import { PipesDetailsSettings } from "../utils/constants";

const DetailView = () => {
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const dispatch = useDispatch();
  const token = useSelector(getAuth);
  const prototypeMode = useSelector(getPrototypeMode);
  const socket = useContext(SocketContext);
  const data = useSelector(getDataProperty) || {};
  const properties = useSelector(getDataProperties) || {};
  useDocumentTitle(`Paul ${data.name ? `- ${data.name}` : ''}`);

  useEffect(() => {
    dispatch(setZoom(false));
    dispatch(setPitch(false));
    return () => dispatch(resetFilterPerformance());
  }, [dispatch]);

  useEffect(() => {
    if (id && token && socket && properties.length) dispatch(setActiveProperty(id));
  }, [socket, id, token, dispatch, properties.length > 0]);

  return (
    <div className={styles.detail_view_wrap}>
      <Container fluid="lg" className={styles.container}>
        <Row>
          {
            id === "62061f10452e21129b8ca241" ? (
              <PerformanceImgType18 />
            ) : prototypeMode
                ? <PerformanceImgTypeFlushing />
                : <PerformanceImgType12 />
          }
          <Col className="flex-row">
            <BackButton to='/'/>
            <Img360View />
          </Col>
        </Row>
        <Row>
          <Col>
            <FilterPerformanceImg />
          </Col>
        </Row>
      </Container>
      <Container fluid="md" className={styles.container_md}>
        <Row>
          <Col>
            <PropertyInform />
          </Col>
        </Row>
        <Row>
          <Col>
            <Sensors />
          </Col>
        </Row>
        <Row>
          <Col>
            <HousesList />
          </Col>
        </Row>
        <Row>
          <Col>
            <PipesDetails card={PipesDetailsSettings} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  )
};
export default DetailView;
