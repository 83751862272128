import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Performance/PerformanceTooltip.module.css';
import moment from 'moment';
import {TooltipWrap} from "../index";
import {useTranslation} from "react-i18next";

const PerformanceTooltip = props => {
  const { area, index } = props;
  const { trackers_positions, id, sensors, show } = area;
  const [name] = useState(index ? 'PAUL' : 'Techem');
  const [sensor, setSensor] = useState({});
  const [statusIsGood, setStatusIsGood] = useState(true)
  const [positions] = useState(trackers_positions[index]);
  const { temperature, updated_at, status } = sensor;
  const { top = 0, left = 0 } = positions;
  const { t } = useTranslation();

  useEffect(() => {
    setSensor(sensors ? sensors.find(s => s.type === name) || {} : {})
  }, [sensors, name]);

  useEffect(() => {
    if(status) {
      setStatusIsGood(status.status);
    } else {
      setStatusIsGood(false);
    }
  }, [status]);

  return (
    temperature || show ? (
      <TooltipWrap id={id} date={updated_at ? moment(updated_at).format(t('time.format')) : null}>
        <div
            className={[styles.container + ' ' + (statusIsGood ? styles.status_good : styles.status_bad)]}
            style={{ top: `${top}px`, left: `${left}px`}}
        >
          <div className={styles.tracker_name}>{name.slice(0, 1)}</div>
          <div className={styles.tracker_temperature}>
            {temperature && temperature < 100
                ? <>{`${temperature.toFixed(1)}`}<span>°C</span></>
              : '-'}
          </div>
        </div>
      </TooltipWrap>
    ) : null
  )
};

export default PerformanceTooltip;
