import React from 'react';
import {TableCell, TableRow} from "@mui/material";
import {DenseTable, StartFlushingForFilters} from "../index";
import PrinterIconSVG from "../../icons/printer.svg";
import {useTranslation} from "react-i18next";
import moment from "moment";

import styles from "../../styles/components/DetailsCard/InformationFlushingProcess.module.css";

const InformationFlushingProcess = props => {
    const name = 'FlushingProcess';
    const { t } = useTranslation();
    const flushLogs = [
        {date: new Date(), time: "08:00", rinsingTime: 5},
        {date: moment("2022-07-10"), time: "09:00", rinsingTime: 5},
        {date: moment("2022-07-5"), time: "12:00", rinsingTime: 5},
        {date: moment("2022-07-2"), time: "01:00", rinsingTime: 5},
        {date: moment("2022-06-17"), time: "04:00", rinsingTime: 5},
        {date: moment("2022-05-9"), time: "10:00", rinsingTime: 5},
    ];
    
    return (
        <div className={`flex-row ${styles.container}`}>
            <StartFlushingForFilters />

            <div className={styles.flushingInfoContainer}>
                <div className={`flex-row ${styles.tableHeader}`}>
                    <h2>{t(`${name}.tableTitle`)}</h2>
                    <img src={PrinterIconSVG} alt="Printer icon" />
                </div>
                <DenseTable header={[
                {text: t(`${name}.tableDateColumnTitle`), align: 'left', style: {fontSize: '16px', fontWeight: '400'}},
                {text: t(`${name}.tableTimeColumnTitle`), align: 'left', style: {fontSize: '16px', fontWeight: '400'}},
                {text: t(`${name}.tableRinsingTimeColumnTitle`), align: 'left', style: {fontSize: '16px', fontWeight: '400'}},
                ]}
                customStyles={true}
                >
                    { flushLogs.map(flush => (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell align="left" width="50%">{moment(flush.date).format("D. MMMM YYYY")}</TableCell>
                            <TableCell align="left" width="25%">{flush.time} Uhr</TableCell>
                            <TableCell align="left">{flush.rinsingTime} min</TableCell>
                        </TableRow>
                    ))}
            </DenseTable>
            </div>
        </div>
    )
}

export default InformationFlushingProcess;