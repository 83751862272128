import {getFromLocalStorage, setToLocalStorage} from "./functions";
import {put} from "redux-saga/effects";
import {RemoveAuth} from "../redux/actions";

// const urlBack = 'https://api.dev.back.marketplace.paul-cloud.de/';
const urlBack = 'https://api.dev.back.demo.live.paul-cloud.de/';
// const urlBack = 'https://api.back.demo.live.paul-cloud.de/';


const API = (function(){
  const _token = () => getFromLocalStorage('token') || null;
  const checkStatus = res => {
    if (res.status >= 200 && res.status < 300) {
      return res.text().then(text => {
        return text ? JSON.parse(text) : {}
      });
    } else {
      return res.text().then(text => {
        if (text && !/<[a-z][\s\S]*>/i.test(text)) {
          const {error} = JSON.parse(text);
          throw {
              formError: (error && Array.isArray(error) && error.length > 0) ? error[0] : "An unexpected error has occurred",
              code: res.status
          };
        } else {
          throw { code: res.status }
        }
      })
    }
  };
  return {
    get: (url) => fetch(urlBack + url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${_token()}`
        }
      }
    ).then(checkStatus),
    post: (url, body) => fetch(urlBack+url, {
        method: 'POST',
        headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${_token()}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify(body)
      }
    ).then(checkStatus),
    put: (url, body) => fetch(urlBack+url, {
        method: 'PUT',
        headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer ${_token()}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify(body)
      }
    ).then(checkStatus),
  };

})();


function* sessionExpires(error) {
    if (error.code === 502 || error.code === 403) {
        // yield put(requestError('CRITICAL_ERROR', { message } ));
        console.log('session expires...')
        yield put(RemoveAuth());
        setToLocalStorage('token', null);
        setToLocalStorage('prototypeMode', false);
    }
}

const requestSuccess = (type, payload) => ({ type: `${type}_SUCCEEDED`, payload });
const requestError = (type, payload) => ({ type: `${type}_FAILED`, payload });

export {API, urlBack, sessionExpires, requestSuccess, requestError};
