import React, {useEffect, useState} from 'react';
import styles from "../../styles/components/Houses/SensorCardForHouseList.module.css";
import {Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";
import TemperatureBox from "./TemperatureBox";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getDemoVersion} from "../../redux/selectors";
import {exceptionsTemperatureRule} from "../../utils/constants";

const TabContent = props => {
    const { type, modbus } = props;
    return (
        typeof modbus === "object"
            ? (<div className="flex-row flex-top-center w-100 flex-evenly text-start w-100">
                <TemperatureBox modbus={modbus.sensor_in} type={'sensor_in'} tabType={type} />
                <TemperatureBox modbus={modbus.sensor_out} type={'sensor_out'} tabType={type} />
            </div>)
            : (<div className={styles.one_temp_box}>
                <TemperatureBox modbus={modbus} tabType={type} />
            </div>)
    );
}

const TemperatureTabs = props => {
    const { id: _propertyId } = useParams();
    const propertyId = _propertyId.split('&')[0];
    const { card } = props;
    const { modbus = null } = card;
    const [tabType, setTabType] = useState('Techem')
    const exception = +modbus === 101 ? false : exceptionsTemperatureRule[propertyId].includes(modbus);
    const demoVersion = useSelector(getDemoVersion);

    useEffect(() => {
        if (exception) setTabType('PAUL')
    }, [exception]);

    return modbus
        ? <div className={`flex-center flex-column ${styles.wrap_temperature_box}`}>
            {exception ? (
                <Tabs
                    transition={false}
                    activeKey={tabType}
                    onSelect={(k) => setTabType(k)}
                    className={`tabs mb-3 ${demoVersion ? 'disable_tab' : ''}`}
                >
                    <Tab eventKey={tabType} title={tabType} className="tab">
                        <TabContent modbus={modbus} type={tabType} />
                    </Tab>
                </Tabs>
            ) : (
                <Tabs
                    transition={false}
                    activeKey={tabType}
                    onSelect={(k) => setTabType(k)}
                    className={`tabs mb-3 ${demoVersion ? 'disable_tab' : ''}`}
                >
                    <Tab eventKey="Techem" title="Techem" className="tab">
                        <TabContent modbus={+modbus === 101 ? 22 : modbus} type='Techem' />
                    </Tab>
                    <Tab eventKey="PAUL" title="PAUL" className="tab">
                        <TabContent modbus={modbus} type='PAUL' />
                    </Tab>
                </Tabs>
            )}
        </div>
        : null
};

export default TemperatureTabs;