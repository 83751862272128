import React from 'react';
import Button from '@mui/material/Button';

import '../../styles/components/Inputs/Button.css';

const PrimaryButton = props => {
  const {text, isModal = false, onClick} = props; 
  
  return (
    <Button style={{width: isModal ? '146px' : '156px', height: isModal ? '30px' : '50px'}} className={'primaryButton'} variant="contained" onClick={() => onClick(true)}>{text}</Button>
  )
};
export default PrimaryButton;