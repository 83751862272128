import React from 'react';
import styles from '../styles/components/TooltipWrap.module.css'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Trans } from 'react-i18next'

const TooltipWrap = (props) => {
    const { children, id, date, tooltipLineBreak = null } = props;

    return (
        <OverlayTrigger
            key={id}
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${id}`} className={styles.tooltip}>
                    {
                        tooltipLineBreak ? (
                            <Trans
                                i18nKey={tooltipLineBreak.name}
                                values={{ n: tooltipLineBreak.parameters}}
                                components={[<br/>]}
                            />
                        ) : (
                            date || "- : -"
                        )
                    }
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipWrap;