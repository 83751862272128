import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getActiveSensor, getDataProperty, getFilterPerformance, getImgSupport} from "../../redux/selectors/index";
import PerformanceTooltip from "./PerformanceTooltip";
import PerformanceImg from "./PerformanceImg";
import * as animationData from '../../images/animation/pulse.json';
import Lottie from 'lottie-react';
import {modbusType, performanceImgType12} from "../../utils/constants";

import styles from "../../styles/components/Performance/PerformanceImg.module.css";
import {SensorCard, TooltipWrap} from "../index";
import {removeActiveSensor} from "../../redux/actions";
import {useParams} from "react-router";

const PerformanceImgType12 = () => {
    const dispatch = useDispatch();
    const imgSupport = useSelector(getImgSupport);
    const filters = useSelector(getFilterPerformance);
    const modbusActiveFilters = Object.values(filters).reduce((acc, n) => n.show ? [...acc, ...n.modbus] : acc, []);
    const name = 'PerformanceTooltip';
    const data = useSelector(getDataProperty);
    const active_sensors = useSelector(getActiveSensor);
    const {sensors = []} = data;
    const { id: propertyId } = useParams();
    const isExistTechem = modbusType[propertyId].includes('Techem');
    const isExistPaul = modbusType[propertyId].includes('PAUL');

    const deleteActiveCard = () => dispatch(removeActiveSensor());

    const onClick = (area) => {
        if (!area.id.toString().includes('m') && active_sensors.includes(area.id)) {
            deleteActiveCard();
        }
    };

    const renderElement = (area) => {
        return (
            <>
                <TooltipWrap id={area.id} date={`${area.tooltip_text}`}>
                    <div className={styles.performance_mouse_hover} onClick={() => onClick(area)}/>
                </TooltipWrap>

                {
                    !area.id.toString().includes('m') ? (
                        <>
                            <div
                                className={[styles.renderElement_wrap + ' ' + (!modbusActiveFilters.includes(area.id) ? styles.hidden_tooltip : '')]}>
                                {isExistTechem ? <PerformanceTooltip area={area} index={0}/> : null}
                                {isExistPaul ? <PerformanceTooltip area={area} index={1}/> : null}
                            </div>
                            {area.status !== "true" || area.status === null ? (
                                <div className={styles.animation}>
                                    <Lottie animationData={animationData} width={40} height={40}/>
                                </div>
                            ) : null}
                        </>
                    ) : null
                }
                {sensors && active_sensors.map((modbus) => {
                    if (modbus === area.id) {
                        const card = sensors.find(s => modbus === s.modbus)
                        return (
                            <div key={area.id}
                                 className={`${styles.card_wrap} ${card ? styles.show : ''} sensor-card-wrap`}>
                                <SensorCard card={card} isModal={true}/>
                            </div>
                        )
                    } else
                        return null;
                })}
            </>
        )
    };

    const mapArea = [
        {
            width: "8.08%",
            height: "19%",
            left: "67.6%",
            top: "66.34%",
            id: 'm101',
            tooltip_text: [`${name}.boiler`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.78%",
            height: "2.93%",
            left: "66.25%",
            top: "55.3%",
            id: 'm102',
            tooltip_text: [`${name}.heating_circuit_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.5%",
            left: "60.77%",
            top: "64%",
            id: 'm103',
            tooltip_text: [`${name}.storage_charging_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "5.34%",
            height: "20%",
            left: "52%",
            top: "58.5%",
            id: 'm100',
            tooltip_text: [`${name}.storage_water_heater`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "1.92%",
            height: "3.3%",
            left: "48.5%",
            top: "58%",
            id: 'm104',
            tooltip_text: [`${name}.circulation_pump`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "2.6%",
            height: "8.5%",
            left: "37.76%",
            top: "58.78%",
            id: 'm105',
            tooltip_text: [`${name}.backwash_filters`],
            className: `${styles.elements}`,
            //style: {background: 'rgba(255, 0, 0, 0.5)'},
            render: renderElement,
        },
        {
            width: "4.5%",
            height: "4%",
            left: "30%",
            top: "55%",
            id: 'm108',
            tooltip_text: [`${name}.domestic_water_meters`],
            className: `${styles.elements}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "37.62%",
            top: "49.86%",
            id: 160,
            tooltip_text: [`${name}.pipe`, {n: 1}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "40.58%",
            top: "51.51%",
            id: 161,
            tooltip_text: [`${name}.pipe`, {n: 2}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            className: `image-map__map ${styles.sensor_style}`,
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "43.53%",
            top: "53.11%",
            id: 162,
            tooltip_text: [`${name}.pipe`, {n: 3}],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.7%",
            top: "55.58%",
            id: 151,
            tooltip_text: [`${name}.hot_water`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: -25, left: 35},
                {top: 0, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "50.47%",
            top: "58.58%",
            id: 152,
            tooltip_text: [`${name}.circulation_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "59.29%",
            top: "62.9%",
            id: 232,
            tooltip_text: [`${name}.hot_water_storage_tank`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "66.34%",
            top: "51.88%",
            id: 212,
            tooltip_text: [`${name}.heating_circuit_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "67.85%",
            top: "52.05%",
            id: 213,
            tooltip_text: [`${name}.heating_circuit_return`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "65.9%",
            top: "66.53%",
            id: 200,
            tooltip_text: [`${name}.boiler_feed`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "63.96%",
            top: "73.92%",
            id: 201,
            tooltip_text: [`${name}.boiler_return_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "58.6%",
            top: "70.99%",
            id: 233,
            tooltip_text: [`${name}.hot_tank_flow`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: 35},
                {top: 45, left: 35},
            ],
            render: renderElement,
        },
        {
            width: "1.5%",
            height: "3.8%",
            left: "35.25%",
            top: "58.05%",
            id: 100,
            tooltip_text: [`${name}.cold_water_inlet`],
            // style: { background: 'rgba(255, 0, 0, 0.5)' },
            className: `image-map__map ${styles.sensor_style}`,
            trackers_positions: [
                {top: 20, left: -45},
                {top: 45, left: -45},
            ],
            render: renderElement,
        },
    ];

    return (
        <PerformanceImg mapArea={mapArea}
                        images={imgSupport ? performanceImgType12.imagesWebp : performanceImgType12.imagesPng}/>
    )
}

export default PerformanceImgType12;
