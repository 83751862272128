import React from 'react';
import Button from '@mui/material/Button';

import '../../styles/components/Inputs/Button.css';

const GreyButton = props => {
  const {text, isModal = false, onClick} = props; 
  
  return (
    <Button style={{width: '121px', height: isModal ? '30px' : '50px'}} className={'greyButton'} variant="contained" onClick={() => onClick(false, null)}>{text}</Button>
  )
};
export default GreyButton;