import React, {useState, useEffect, useRef} from 'react';
import styles from '../../styles/components/Sencors/StartFlushingForPipes.module.css';
import { FlushingProcessForPipes } from "../index";
import { PrimaryButton } from "../Inputs/index";
import flushAnimation from '../../images/animation/flush_animation.png';
import flushSuccessIconSVG from '../../icons/checkmark.svg';
import { CSSTransition } from 'react-transition-group';
import {useTranslation} from "react-i18next";

import "../../styles/components/Sencors/TransitionGroup.css";

const StartFlushingForPipes = props => {
  const {temperature} = props;
  const name = 'FlushingProcess';
  const [flushProcess, setFlushProcess] = useState(false);
  const [showStartFlushing, setShowStartFlushing] = useState(true);
  const [showSuccessFlushing, setShowSuccessFlushing] = useState(false);
  const [flushStatus, setFlushStatus] = useState(null);
  const { t } = useTranslation();
  let flushTimer = useRef(null);

  useEffect(() => {
    if(flushProcess) {
      flushTimer.current = setTimeout(() => {
        setFlushProcess(false);
        setFlushStatus(true);
      }, 60000);
    } else {
      clearTimeout(flushTimer.current);
    }
  }, [flushProcess]);

  const updateFlushingProcess = (data, status) => {
    setFlushProcess(data);
    setFlushStatus(status);
  }

  useEffect(() => { 
    if(flushStatus !== null) {
      setTimeout(() => {
        setFlushStatus(null);
        setShowSuccessFlushing(false);
      }, 5000)
    }
  }, [flushStatus])

  return (
    <div className={`flex-column ${styles.container}`}>
      { showStartFlushing && (
        <>
          <img src={flushAnimation} alt="flush animation" className={`${styles.animationIcon} ${flushStatus ? styles.animationOpacity : ''}`}/>
          <PrimaryButton text={t(`${name}.buttonText`)} isModal onClick={updateFlushingProcess}/>
        </>
      )}
      <CSSTransition
        in={showSuccessFlushing}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowStartFlushing(false)}
        onExited={() => setShowStartFlushing(true)}
      >
        <>
          <img src={flushAnimation} alt="flush animation" className={`${styles.animationIcon} ${flushStatus ? styles.animationOpacity : ''}`}/>
          <img src={flushSuccessIconSVG} alt="" className={styles.successIcon}/>
          <p className={styles.successTitle}>{t(`${name}.flushingSeccessTitle`)}</p>
        </>
      </CSSTransition>
      <CSSTransition
        in={flushProcess}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowStartFlushing(false)}
        onExited={() => {
          if(flushStatus) {
            setShowSuccessFlushing(true);
          } else {
            setShowStartFlushing(true);
          }
        }}
      >
        <FlushingProcessForPipes updateFlushingProcess={updateFlushingProcess} temperature={temperature}/>
      </CSSTransition>
    </div>
  )
};

export default StartFlushingForPipes;
