import  * as React from 'react';
import '../../styles/components/Sencors/SensorCardFlushingInfo.css';
import { List, ListItem, ListItemText, Divider, Typography } from '@mui/material'
import {useTranslation} from "react-i18next";

const SensorCardFlushingInfo = props => {
  const {sensors} = props;
  const { t } = useTranslation();

  return (
    <div className={`flex-column flush_info_container`}>
        <List sx={{ width: '100%'}}>
            <ListItem alignItems="center">
                <ListItemText 
                    primary={t('HousesList.temperature')} 
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="p"
                            >
                                {sensors[0].temperature.toFixed(1)}
                            </Typography>
                            {<p className={'symbol degreesSymbol'}>°C</p>}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
            <ListItem alignItems="center">
                <ListItemText 
                    primary={t('sensorPrototype.volumeFlow')} 
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="p"
                            >
                                500
                            </Typography>
                            {<p className={'symbol'}>l/h</p>}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
            <ListItem alignItems="center">
                <ListItemText 
                    primary={t('sensorPrototype.h')} 
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="p"
                            >
                                53.5
                            </Typography>
                            {<p className={'symbol'}>%</p>}
                        </React.Fragment>
                    }
                />
            </ListItem>
        </List>
    </div>
  )
};

export default SensorCardFlushingInfo;
