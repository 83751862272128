import { buildings } from '../images/buildings';
import { s5 as sensors_241_5, s7 as sensors_241_7} from '../images/houses/62061f10452e21129b8ca241';
import { sNull as sensors_242_null } from '../images/houses/62061f10452e21129b8ca242';
import { images_5 as sensors_241_images_5 } from '../images/houses/62061f10452e21129b8ca241/5/details';
import { images_7 as sensors_241_images_7 } from '../images/houses/62061f10452e21129b8ca241/7/details';
import { images as sensors_242_images } from '../images/houses/62061f10452e21129b8ca242/details_index';
import { mainz} from '../images/360/mainz';
import { rhein5, rhein7 } from '../images/360/rhein';

import img_18_webp from '../images/performance/18/master_bg_02.webp';
import ChilledWaterImg_18_webp from '../images/performance/18/Kaltwasser.webp';
import heatingReturnFlowImg_18_webp from '../images/performance/18/Heizung_Rücklauf.webp';
import heatingLeadTimeImg_18_webp from '../images/performance/18/Heizung_Vorlauf.webp';
import hotWaterImg_18_webp from '../images/performance/18/Warmwasser.webp';
import circulationImg_18_webp from '../images/performance/18/Zirkulation.webp';
import img_18_png from '../images/performance/18/master_bg_02.png';
import ChilledWaterImg_18_png from '../images/performance/18/Kaltwasser.png';
import heatingReturnFlowImg_18_png from '../images/performance/18/Heizung_Rücklauf.png';
import heatingLeadTimeImg_18_png from '../images/performance/18/Heizung_Vorlauf.png';
import hotWaterImg_18_png from '../images/performance/18/Warmwasser.png';
import circulationImg_18_png from '../images/performance/18/Zirkulation.png';

import img_12_webp from '../images/performance/12/fn_master_bg_01.webp';
import ChilledWaterImg_12_webp from '../images/performance/12/Kaltwasser.webp';
import heatingReturnFlowImg_12_webp from '../images/performance/12/Heizung_Rücklauf.webp';
import heatingLeadTimeImg_12_webp from '../images/performance/12/Heizung_Vorlauf.webp';
import hotWaterImg_12_webp from '../images/performance/12/Warmwasser.webp';
import circulationImg_12_webp from '../images/performance/12/Zirkulation.webp';
import img_12_png from '../images/performance/12/fn_master_bg_01.png';
import ChilledWaterImg_12_png from '../images/performance/12/Kaltwasser.png';
import heatingReturnFlowImg_12_png from '../images/performance/12/Heizung_Rücklauf.png';
import heatingLeadTimeImg_12_png from '../images/performance/12/Heizung_Vorlauf.png';
import hotWaterImg_12_png from '../images/performance/12/Warmwasser.png';
import circulationImg_12_png from '../images/performance/12/Zirkulation.png';

import img_flushing_webp from '../images/performance/flushing/background_2.webp';
import ChilledWaterImg_flushing_webp from '../images/performance/flushing/Kaltwasser.webp';
import heatingReturnFlowImg_flushing_webp from '../images/performance/flushing/Heizung_Rücklauf.webp';
import heatingLeadTimeImg_flushing_webp from '../images/performance/flushing/Heizung_Vorlauf.webp';
import hotWaterImg_flushing_webp from '../images/performance/flushing/Warmwasser.webp';
import circulationImg_flushing_webp from '../images/performance/flushing/Zirkulation.webp';
import img_flushing_png from '../images/performance/flushing/background_2.png';
import ChilledWaterImg_flushing_png from '../images/performance/flushing/Kaltwasser.png';
import heatingReturnFlowImg_flushing_png from '../images/performance/flushing/Heizung_Rücklauf.png';
import heatingLeadTimeImg_flushing_png from '../images/performance/flushing/Heizung_Vorlauf.png';
import hotWaterImg_flushing_png from '../images/performance/flushing/Warmwasser.png';
import circulationImg_flushing_png from '../images/performance/flushing/Zirkulation.png';

import img_prototype_png from '../images/performance/prototype/master.png';
import img_prototype_webp from '../images/performance/prototype/master.webp';
import heatingLeadTimeImg_prototype_png from '../images/performance/prototype/Heizung_Vorlauf.png';
import heatingLeadTimeImg_prototype_webp from '../images/performance/prototype/Heizung_Vorlauf.webp';
import heatingReturnFlowImg_prototype_png from '../images/performance/prototype/Heizung_Rücklauf.png';
import heatingReturnFlowImg_prototype_webp from '../images/performance/prototype/Heizung_Rücklauf.webp';


export const performanceImgType18 = {
  imagesWebp: [img_18_webp, ChilledWaterImg_18_webp, heatingReturnFlowImg_18_webp, heatingLeadTimeImg_18_webp, hotWaterImg_18_webp, circulationImg_18_webp ],
  imagesPng: [img_18_png, ChilledWaterImg_18_png, heatingReturnFlowImg_18_png, heatingLeadTimeImg_18_png, hotWaterImg_18_png, circulationImg_18_png]
}

export const performanceImgType12 = {
  imagesWebp: [img_12_webp, ChilledWaterImg_12_webp, heatingReturnFlowImg_12_webp, heatingLeadTimeImg_12_webp, hotWaterImg_12_webp, circulationImg_12_webp ],
  imagesPng: [img_12_png, ChilledWaterImg_12_png, heatingReturnFlowImg_12_png, heatingLeadTimeImg_12_png, hotWaterImg_12_png, circulationImg_12_png]
}

export const performanceImgTypeFlushing = {
  imagesWebp: [img_flushing_webp, ChilledWaterImg_flushing_webp, heatingReturnFlowImg_flushing_webp, heatingLeadTimeImg_flushing_webp, hotWaterImg_flushing_webp, circulationImg_flushing_webp ],
  imagesPng: [img_flushing_png, ChilledWaterImg_flushing_png, heatingReturnFlowImg_flushing_png, heatingLeadTimeImg_flushing_png, hotWaterImg_flushing_png, circulationImg_flushing_png]
}

export const performanceImgTypePrototype = {
  imagesWebp: [img_prototype_webp, ChilledWaterImg_12_webp, heatingReturnFlowImg_prototype_webp, heatingLeadTimeImg_prototype_webp, hotWaterImg_12_webp, circulationImg_12_webp ],
  imagesPng: [img_prototype_png, ChilledWaterImg_12_png, heatingReturnFlowImg_prototype_png, heatingLeadTimeImg_prototype_png, hotWaterImg_12_png, circulationImg_12_png]
}


export const devicesRule = [
  {
    device_uid: '1118232953-865583041233338',
    device_type: 'PAUL',
  },
  {
    device_uid: '1121102674-865583041323030',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118232950-865583041233502',
    device_type: 'PAUL',
  },
  {
    device_uid: '1118229504-865583041231373',
    device_type: 'PAUL',
  },
  {
    device_uid: '901405300029882-867997030810526',
    device_type: 'Techem',
  },
  {
    device_uid: '901405300029885-867997030811136',
    device_type: 'Techem',
  },
  {
    device_uid: '901405300029884-867997032302340',
    device_type: 'Techem',
  },
];

export const sensorListRule = {
  'Trinkwasser': [152, 151, 101, 100, 160, 161, 162, 163, 164, 165, 166],
  'Heizung': [200, 201, 212, 213, 232, 233]
}

export const modbusName = {
  22: {
    componentType: "chilledWater",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "EntranceH5",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Entrance",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Entrance",
      }
    },
    building: 5,
  },
  100: {
    componentType: "chilledWater",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "EntranceH7",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Entrance",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Entrance",
      }
    },
    building: 7,
  },
  101: {
    componentType: "chilledWater",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "EntranceH5",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Entrance",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Entrance",
      }
    },
    building: 5,
  },
  151: {
    componentType: "hotWater",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "Exit",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Exit",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Exit",
      }
    },
    building: 5,
  },
  152: {
    componentType: "circulation",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Rewind",
      }
    },
    building:  5,
  },
  160: {
    componentType: "Pipe1",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house7",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  161: {
    componentType: "Pipe2",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house7",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  162: {
    componentType: "Pipe3",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house7",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  163: {
    componentType: "Pipe4",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house7",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  164: {
    componentType: "Pipe5",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house5",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  165: {
    componentType: "Pipe6",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house5",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  166: {
    componentType: "Pipe7",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "house5",
      },
      '62061f10452e21129b8ca242': {
        name: "",
      },
      '62061f10452e21129b8ca243': {
        name: "",
      }
    },
    building: 7,
  },
  200: {
    componentType: "Boiler",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "FastForward",
      },
      '62061f10452e21129b8ca242': {
        nameType: "FastForward",
      },
      '62061f10452e21129b8ca243': {
        nameType: "FastForward",
      }
    },
    building: 5,
  },
  201: {
    componentType: "Boiler",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Rewind",
      }
    },
    building: 5,
  },
  202: {
    component: "VL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "WEZ_ID202",
      },
      '62061f10452e21129b8ca242': {
        name: "WEZ_ID202",
      },
      '62061f10452e21129b8ca243': {
        name: "WEZ_ID202",
      }
    },
    building: null,
  },
  203: {
    component: "RL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "WEZ_ID203",
      },
      '62061f10452e21129b8ca242': {
        name: "WEZ_ID203",
      },
      '62061f10452e21129b8ca243': {
        name: "WEZ_ID203",
      }
    },
    building: null,
  },
  204: {
    component: "VL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "WEZ_ID204",
      },
      '62061f10452e21129b8ca242': {
        name: "WEZ_ID204",
      },
      '62061f10452e21129b8ca243': {
        name: "WEZ_ID204",
      }
    },
    building: null,
  },
  206: {
    component: "VL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "HW_ID206",
      },
      '62061f10452e21129b8ca242': {
        name: "HW_ID206",
      },
      '62061f10452e21129b8ca243': {
        name: "HW_ID206",
      }
    },
    building: null,
  },
  207: {
    component: "RL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "HW_ID207",
      },
      '62061f10452e21129b8ca242': {
        name: "HW_ID207",
      },
      '62061f10452e21129b8ca243': {
        name: "HW_ID207",
      }
    },
    building: null,
  },
  208: {
    component: "VL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "HW_ID208",
      },
      '62061f10452e21129b8ca242': {
        name: "HW_ID208",
      },
      '62061f10452e21129b8ca243': {
        name: "HW_ID208",
      }
    },
    building: null,
  },
  209: {
    component: "RL",
    localization: {
      '62061f10452e21129b8ca241': {
        name: "HW_ID209",
      },
      '62061f10452e21129b8ca242': {
        name: "HW_ID209",
      },
      '62061f10452e21129b8ca243': {
        name: "HW_ID209",
      }
    },
    building: null,
  },
  212: {
    componentType: "HeatingCircuit",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "FastForward",
      },
      '62061f10452e21129b8ca242': {
        nameType: "FastForward",
      },
      '62061f10452e21129b8ca243': {
        nameType: "FastForward",
      }
    },
    building: 5,
  },
  213: {
    componentType: "HeatingCircuit",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Rewind",
      }
    },
    building: 5,
  },
  232: {
    componentType: "StorageWaterHeater",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "FastForward",
      },
      '62061f10452e21129b8ca242': {
        nameType: "FastForward",
      },
      '62061f10452e21129b8ca243': {
        nameType: "FastForward",
      }
    },
    building: 5,
  },
  233: {
    componentType: "StorageWaterHeater",
    localization: {
      '62061f10452e21129b8ca241': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca242': {
        nameType: "Rewind",
      },
      '62061f10452e21129b8ca243': {
        nameType: "Rewind",
      }
    },
    building: 5,
  }
}

export const imagesRole = [
  {
    device_id: "62061f10452e21129b8ca241",
    images: [
      {
        id: 1,
        name: ["images360.staircaseRoom", "images360.house5"],
        roomId: 1,
        house: 5,
        imgWebp: rhein5.webp.staircase[0],
        imgPng: rhein5.png.staircase[0],
        yaw: 310,
        pitch: 0,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 1,
            pitch: -1,
            yaw: 269,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 2,
            pitch: -1,
            yaw: -2,
            type: 'scene',
            sceneId: 14
          }
        ]
      },
      {
        id: 2,
        name: ["images360.hallwayRoom", "images360.house5"],
        roomId: 2,
        house: 5,
        imgWebp: rhein5.webp.hallway[0],
        imgPng: rhein5.png.hallway[0],
        yaw: 355,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 3,
            pitch: -40,
            yaw: 185,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 4,
            pitch: -25,
            yaw: -1,
            type: 'scene',
            sceneId: 3
          }
        ]
      },
      {
        id: 3,
        roomId: 2,
        house: 5,
        imgWebp: rhein5.webp.hallway[1],
        imgPng: rhein5.png.hallway[1],
        yaw: 100,
        pitch: 0,
        horizonPitch: 8,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 5,
            pitch: 0,
            yaw: 180,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 6,
            pitch: -12,
            yaw: -10,
            type: 'scene',
            sceneId: 4
          },
          {
            id: 7,
            pitch: -27,
            yaw: 120,
            type: 'scene',
            sceneId: 6
          }
        ]
      },
      {
        id: 4,
        name: ["images360.coldWaterInletRoom", "images360.house5"],
        roomId: 3,
        house: 5,
        imgWebp: rhein5.webp.coldWaterInlet[0],
        imgPng: rhein5.png.coldWaterInlet[0],
        yaw: 255,
        pitch: 0,
        horizonPitch: 18,
        horizonRoll: 2,
        hfov: 140,
        hotSpots: [
          {
            id: 8,
            pitch: -45,
            yaw: 237,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 9,
            pitch: -57,
            yaw: 20,
            type: 'scene',
            sceneId: 11
          }
        ]
      },
      {
        id: 5,
        roomId: 3,
        house: 5,
        imgWebp: rhein5.webp.coldWaterInlet[1],
        imgPng: rhein5.png.coldWaterInlet[1],
        yaw: 10,
        pitch: 0,
        horizonPitch: -15,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 10,
            pitch: -5,
            yaw: -75,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 11,
            pitch: -35,
            yaw: 165,
            type: 'scene',
            sceneId: 11
          }
        ]
      },
      {
        id: 6,
        name: ["images360.boilerRoom", "images360.house5"],
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[0],
        imgPng: rhein5.png.boilerRoom[0],
        yaw: 90,
        pitch: 0,
        horizonPitch: 5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 12,
            pitch: -0.5,
            yaw: 195,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 13,
            pitch: -30,
            yaw: 20,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 14,
            pitch: -30,
            yaw: 160,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 15,
            pitch: -35,
            yaw: 60,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 16,
            pitch: 8,
            yaw: 165,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 7,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[1],
        imgPng: rhein5.png.boilerRoom[1],
        yaw: 10,
        pitch: -10,
        horizonPitch: 5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 17,
            pitch: -1,
            yaw: 96,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 18,
            pitch: -20,
            yaw: 75,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 19,
            pitch: -32,
            yaw: 40,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 20,
            pitch: 363,
            yaw: 77,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 8,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[2],
        imgPng: rhein5.png.boilerRoom[2],
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 21,
            pitch: -45,
            yaw: -125,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 22,
            pitch: -32,
            yaw: 117,
            type: 'scene',
            sceneId: 9
          },
          {
            id: 23,
            pitch: -2,
            yaw: 117,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 24,
            pitch: 365,
            yaw: 96,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 9,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[3],
        imgPng: rhein5.png.boilerRoom[3],
        yaw: 320,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 25,
            pitch: -2,
            yaw: 82,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 26,
            pitch: -35,
            yaw: 0,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 27,
            pitch: -22,
            yaw: -57,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 28,
            pitch: -22,
            yaw: 265,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 29,
            pitch: 10,
            yaw: 38,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 10,
        roomId: 4,
        house: 5,
        imgWebp: rhein5.webp.boilerRoom[4],
        imgPng: rhein5.png.boilerRoom[4],
        yaw: 5,
        pitch: 0,
        horizonPitch: -10,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 30,
            pitch: -13,
            yaw: 137,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 31,
            pitch: -15,
            yaw: -63,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 32,
            pitch: -18,
            yaw: 273,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 33,
            pitch: 22,
            yaw: 78,
            name: "icon",
            imgWebp: rhein5.webp.boilerRoom[5],
            imgPng: rhein5.png.boilerRoom[5],
            cssClass: "custom-hotspot"
          },
        ]
      },
      {
        id: 11,
        name: ["images360.gasPipeRoom", "images360.house5"],
        roomId: 5,
        house: 5,
        imgWebp: rhein5.webp.gasPipe[0],
        imgPng: rhein5.png.gasPipe[0],
        yaw: 10,
        pitch: 0,
        horizonPitch: 6,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 34,
            pitch: -32,
            yaw: 260,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 35,
            pitch: -1,
            yaw: 235,
            type: 'scene',
            sceneId: 3
          }
        ]
      },
      {
        id: 12,
        name: ["images360.staircaseRoom", "images360.house7"],
        roomId: 1,
        house: 7,
        imgWebp: rhein7.webp.staircase[0],
        imgPng: rhein7.png.staircase[0],
        yaw: 360,
        pitch: 0,
        horizonPitch: 4,
        horizonRoll: 2,
        hfov: 140,
        hotSpots: [
          {
            id: 36,
            pitch: -15,
            yaw: -2,
            type: 'scene',
            sceneId: 13
          },
          {
            id: 37,
            pitch: -25,
            yaw: 100,
            type: 'scene',
            sceneId: 18
          }
        ]
      },
      {
        id: 13,
        roomId: 1,
        house: 7,
        imgWebp: rhein7.webp.staircase[1],
        imgPng: rhein7.png.staircase[1],
        yaw: 365,
        pitch: 0,
        horizonPitch: 8,
        horizonRoll: -1,
        hfov: 140,
        hotSpots: [
          {
            id: 38,
            pitch: -2,
            yaw: -25,
            type: 'scene',
            sceneId: 12
          }
        ]
      },
      {
        id: 14,
        name: ["images360.hallwayRoom", "images360.house7"],
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[0],
        imgPng: rhein7.png.hallway[0],
        yaw: 20,
        pitch: 0,
        horizonPitch: 25,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [

          {
            id: 39,
            pitch: 0,
            yaw: -210,
            type: 'scene',
            sceneId: 21
          },
          {
            id: 39,
            pitch: 0,
            yaw: -130,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 40,
            pitch: -55,
            yaw: 50,
            type: 'scene',
            sceneId: 15
          },
          {
            id: 43,
            pitch: -2,
            yaw: 55,
            type: 'scene',
            sceneId: 16
          }
        ]
      },
      {
        id: 15,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[1],
        imgPng: rhein7.png.hallway[1],
        yaw: 20,
        pitch: 0,
        horizonPitch: -4,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 41,
            pitch: -2,
            yaw: 65,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 42,
            pitch: -2,
            yaw: 0,
            type: 'scene',
            sceneId: 21
          },
          {
            id: 43,
            pitch: -2,
            yaw: 225,
            type: 'scene',
            sceneId: 16
          }
        ]
      },
      {
        id: 16,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[2],
        imgPng: rhein7.png.hallway[2],
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 44,
            pitch: -5,
            yaw: -27,
            type: 'scene',
            sceneId: 17
          },
          {
            id: 45,
            pitch: -20,
            yaw: 97,
            type: 'scene',
            sceneId: 15
          }
        ]
      },
      {
        id: 17,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[3],
        imgPng: rhein7.png.hallway[3],
        yaw: 350,
        pitch: 0,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 46,
            pitch: -20,
            yaw: 0,
            type: 'scene',
            sceneId: 18
          },
          {
            id: 46,
            pitch: -30,
            yaw: -125,
            type: 'scene',
            sceneId: 16
          }
        ]
      },
      {
        id: 18,
        roomId: 2,
        house: 7,
        imgWebp: rhein7.webp.hallway[4],
        imgPng: rhein7.png.hallway[4],
        yaw: 10,
        pitch: 0,
        horizonPitch: 2,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 47,
            pitch: -35,
            yaw: 87,
            type: 'scene',
            sceneId: 19
          },
          {
            id: 48,
            pitch: -5,
            yaw: 15,
            type: 'scene',
            sceneId: 12
          },
          {
            id: 49,
            pitch: -1,
            yaw: 181,
            type: 'scene',
            sceneId: 17
          }
        ]
      },
      {
        id: 19,
        name: ["images360.coldWaterInletRoom", "images360.house7"],
        roomId: 3,
        house: 7,
        imgWebp: rhein7.webp.coldWaterInlet[0],
        imgPng: rhein7.png.coldWaterInlet[0],
        yaw: 40,
        pitch: 0,
        horizonPitch: 10,
        horizonRoll: 1,
        hfov: 140,
        hotSpots: [
          {
            id: 50,
            pitch: 0,
            yaw: 175,
            type: 'scene',
            sceneId: 18
          },
          {
            id: 51,
            pitch: -37,
            yaw: 50,
            type: 'scene',
            sceneId: 20
          }
        ]
      },
      {
        id: 20,
        roomId: 3,
        house: 7,
        imgWebp: rhein7.webp.coldWaterInlet[1],
        imgPng: rhein7.png.coldWaterInlet[1],
        yaw: 5,
        pitch: -20,
        horizonPitch: -20,
        horizonRoll: -2,
        hfov: 140,
        hotSpots: [
          {
            id: 52,
            pitch: -45,
            yaw: 105,
            type: 'scene',
            sceneId: 19
          },
          {
            id: 53,
            pitch: -5,
            yaw: 98,
            type: 'scene',
            sceneId: 18
          }
        ]
      },
      {
        id: 21,
        name: ["images360.electricityMeterRoom", "images360.house7"],
        roomId: 4,
        house: 7,
        imgWebp: rhein7.webp.electricityMeter[0],
        imgPng: rhein7.png.electricityMeter[0],
        yaw: 220,
        pitch: 0,
        horizonPitch: 16,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 54,
            pitch: 15,
            yaw: -120,
            type: 'scene',
            sceneId: 22
          },
          {
            id: 55,
            pitch: 0,
            yaw: 180,
            type: 'scene',
            sceneId: 15
          }
        ]
      },
      {
        id: 22,
        roomId: 4,
        house: 7,
        imgWebp: rhein7.webp.electricityMeter[1],
        imgPng: rhein7.png.electricityMeter[1],
        yaw: -10,
        pitch: 0,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 56,
            pitch: -62,
            yaw: 145,
            type: 'scene',
            sceneId: 21
          }
        ]
      }
    ]
  },
  {
    device_id: "62061f10452e21129b8ca242",
    images: [
      {
        id: 1,
        name: ["images360.staircaseRoom"],
        roomId: 1,
        imgWebp: mainz.webp.staircase[0],
        imgPng: mainz.png.staircase[0],
        yaw: 355,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        house: null,
        hotSpots: [
          {
            id: 1,
            pitch: 187,
            yaw: 236,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 2,
            pitch: 187,
            yaw: 120,
            type: 'scene',
            sceneId: 13
          }
        ]
      },
      {
        id: 2,
        name: ["images360.hallwayRoom"],
        roomId: 2,
        imgWebp: mainz.webp.hallway[0],
        imgPng: mainz.png.hallway[0],
        house: null,
        yaw: 0,
        pitch: 0,
        horizonPitch: 20,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 3,
            pitch: 187,
            yaw: 137,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 4,
            pitch: 200,
            yaw: 5,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 5,
            pitch: 187,
            yaw: 220,
            type: 'scene',
            sceneId: 5
          }
        ]
      },
      {
        id: 3,
        name: ["images360.coldWaterInletRoom"],
        roomId: 3,
        yaw: 10,
        pitch: 0,
        horizonPitch: -20,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.coldWaterInlet[0],
        imgPng: mainz.png.coldWaterInlet[0],
        house: null,
        hotSpots: [
          {
            id: 6,
            pitch: 180,
            yaw: 185,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 7,
            pitch: 215,
            yaw: 185,
            type: 'scene',
            sceneId: 4
          }
        ]
      },
      {
        id: 4,
        roomId: 3,
        yaw: -30,
        pitch: -10,
        horizonPitch: 0,
        horizonRoll: 2,
        hfov: 140,
        imgWebp: mainz.webp.coldWaterInlet[1],
        imgPng: mainz.png.coldWaterInlet[1],
        house: null,
        hotSpots: [
          {
            id: 8,
            pitch: -25,
            yaw: 80,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 9,
            pitch: 205,
            yaw: 90,
            type: 'scene',
            sceneId: 9
          },
        ]
      },
      {
        id: 5,
        name: ["images360.boilerRoom"],
        roomId: 4,
        yaw: 40,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[0],
        imgPng: mainz.png.boilerRoom[0],
        house: null,
        hotSpots: [
          {
            id: 10,
            pitch: 180,
            yaw: -10,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 11,
            pitch: 203,
            yaw: 225,
            type: 'scene',
            sceneId: 6
          }
        ]
      },
      {
        id: 6,
        roomId: 4,
        yaw: 0,
        pitch: 0,
        horizonPitch: 19,
        horizonRoll: 1,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[1],
        imgPng: mainz.png.boilerRoom[1],
        house: null,
        hotSpots: [
          {
            id: 12,
            pitch: -40,
            yaw: 80,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 13,
            pitch: 185,
            yaw: 220,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 14,
            pitch: -47,
            yaw: 260,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 100,
            pitch: 12,
            yaw: 276,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 7,
        roomId: 4,
        yaw: 90,
        pitch: -10,
        horizonPitch: 9,
        horizonRoll: 2,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[2],
        imgPng: mainz.png.boilerRoom[2],
        house: null,
        hotSpots: [
          {
            id: 15,
            pitch: -43,
            yaw: 170,
            type: 'scene',
            sceneId: 6
          },
          {
            id: 16,
            pitch: -55,
            yaw: 65,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 101,
            pitch: 20,
            yaw: -2,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 8,
        roomId: 4,
        yaw: 10,
        pitch: 0,
        horizonPitch: 18,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[3],
        imgPng: mainz.png.boilerRoom[3],
        house: null,
        hotSpots: [
          {
            id: 17,
            pitch: -55,
            yaw: -120,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 102,
            pitch: -15,
            yaw: -40,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 9,
        name: ["images360.electricityMeterRoom"],
        roomId: 5,
        yaw: 270,
        pitch: -10,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[0],
        imgPng: mainz.png.electricityMeter[0],
        house: null,
        hotSpots: [
          {
            id: 18,
            pitch: 210,
            yaw: 27,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 19,
            pitch: 192,
            yaw: 85,
            type: 'scene',
            sceneId: 11
          },
        ]
      },
      {
        id: 10,
        roomId: 5,
        yaw: 8,
        pitch: -5,
        horizonPitch: -6,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[1],
        imgPng: mainz.png.electricityMeter[1],
        house: null,
        hotSpots: [
          {
            id: 20,
            pitch: 197,
            yaw: 185,
            type: 'scene',
            sceneId: 4
          }
        ]
      },
      {
        id: 11,
        roomId: 5,
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[2],
        imgPng: mainz.png.electricityMeter[2],
        house: null,
        hotSpots: [
          {
            id: 21,
            pitch: 205,
            yaw: 125,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 22,
            pitch: 182,
            yaw: 228,
            type: 'scene',
            sceneId: 12
          },
        ]
      },
      {
        id: 12,
        roomId: 5,
        yaw: 0,
        pitch: -5,
        horizonPitch: -8,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[3],
        imgPng: mainz.png.electricityMeter[3],
        house: null,
        hotSpots: [
          {
            id: 23,
            pitch: 176,
            yaw: 135,
            type: 'scene',
            sceneId: 11
          },
          {
            id: 24,
            pitch: 200,
            yaw: 115,
            type: 'scene',
            sceneId: 10
          }
        ]
      },
      {
        id: 13,
        name: ["images360.gasPipeRoom"],
        roomId: 6,
        yaw: 10,
        pitch: 20,
        horizonPitch: 20,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.gasPipe[0],
        imgPng:  mainz.png.gasPipe[0],
        house: null,
        hotSpots: [
          {
            id: 25,
            pitch: 180,
            yaw: 260,
            type: 'scene',
            sceneId: 1
          }
        ]
      }
    ]
  },
  {
    device_id: "62061f10452e21129b8ca243",
    images: [
      {
        id: 1,
        name: ["images360.staircaseRoom"],
        roomId: 1,
        imgWebp: mainz.webp.staircase[0],
        imgPng: mainz.png.staircase[0],
        yaw: 355,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        house: null,
        hotSpots: [
          {
            id: 1,
            pitch: 187,
            yaw: 236,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 2,
            pitch: 187,
            yaw: 120,
            type: 'scene',
            sceneId: 13
          }
        ]
      },
      {
        id: 2,
        name: ["images360.hallwayRoom"],
        roomId: 2,
        imgWebp: mainz.webp.hallway[0],
        imgPng: mainz.png.hallway[0],
        house: null,
        yaw: 0,
        pitch: 0,
        horizonPitch: 20,
        horizonRoll: 0,
        hfov: 140,
        hotSpots: [
          {
            id: 3,
            pitch: 187,
            yaw: 137,
            type: 'scene',
            sceneId: 1
          },
          {
            id: 4,
            pitch: 200,
            yaw: 5,
            type: 'scene',
            sceneId: 3
          },
          {
            id: 5,
            pitch: 187,
            yaw: 220,
            type: 'scene',
            sceneId: 5
          }
        ]
      },
      {
        id: 3,
        name: ["images360.coldWaterInletRoom"],
        roomId: 3,
        yaw: 10,
        pitch: 0,
        horizonPitch: -20,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.coldWaterInlet[0],
        imgPng: mainz.png.coldWaterInlet[0],
        house: null,
        hotSpots: [
          {
            id: 6,
            pitch: 180,
            yaw: 185,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 7,
            pitch: 215,
            yaw: 185,
            type: 'scene',
            sceneId: 4
          }
        ]
      },
      {
        id: 4,
        roomId: 3,
        yaw: -30,
        pitch: -10,
        horizonPitch: 0,
        horizonRoll: 2,
        hfov: 140,
        imgWebp: mainz.webp.coldWaterInlet[1],
        imgPng: mainz.png.coldWaterInlet[1],
        house: null,
        hotSpots: [
          {
            id: 8,
            pitch: -25,
            yaw: 80,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 9,
            pitch: 205,
            yaw: 90,
            type: 'scene',
            sceneId: 9
          },
        ]
      },
      {
        id: 5,
        name: ["images360.boilerRoom"],
        roomId: 4,
        yaw: 40,
        pitch: -5,
        horizonPitch: 0,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[0],
        imgPng: mainz.png.boilerRoom[0],
        house: null,
        hotSpots: [
          {
            id: 10,
            pitch: 180,
            yaw: -10,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 11,
            pitch: 203,
            yaw: 225,
            type: 'scene',
            sceneId: 6
          }
        ]
      },
      {
        id: 6,
        roomId: 4,
        yaw: 0,
        pitch: 0,
        horizonPitch: 19,
        horizonRoll: 1,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[1],
        imgPng: mainz.png.boilerRoom[1],
        house: null,
        hotSpots: [
          {
            id: 12,
            pitch: -40,
            yaw: 80,
            type: 'scene',
            sceneId: 5
          },
          {
            id: 13,
            pitch: 185,
            yaw: 220,
            type: 'scene',
            sceneId: 2
          },
          {
            id: 14,
            pitch: -47,
            yaw: 260,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 100,
            pitch: 12,
            yaw: 276,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 7,
        roomId: 4,
        yaw: 90,
        pitch: -10,
        horizonPitch: 9,
        horizonRoll: 2,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[2],
        imgPng: mainz.png.boilerRoom[2],
        house: null,
        hotSpots: [
          {
            id: 15,
            pitch: -43,
            yaw: 170,
            type: 'scene',
            sceneId: 6
          },
          {
            id: 16,
            pitch: -55,
            yaw: 65,
            type: 'scene',
            sceneId: 8
          },
          {
            id: 101,
            pitch: 20,
            yaw: -2,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 8,
        roomId: 4,
        yaw: 10,
        pitch: 0,
        horizonPitch: 18,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.boilerRoom[3],
        imgPng: mainz.png.boilerRoom[3],
        house: null,
        hotSpots: [
          {
            id: 17,
            pitch: -55,
            yaw: -120,
            type: 'scene',
            sceneId: 7
          },
          {
            id: 102,
            pitch: -15,
            yaw: -40,
            name: "icon",
            imgWebp: mainz.webp.boilerRoom[4],
            imgPng: mainz.png.boilerRoom[4],
            cssClass: "custom-hotspot"
          }
        ]
      },
      {
        id: 9,
        name: ["images360.electricityMeterRoom"],
        roomId: 5,
        yaw: 270,
        pitch: -10,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[0],
        imgPng: mainz.png.electricityMeter[0],
        house: null,
        hotSpots: [
          {
            id: 18,
            pitch: 210,
            yaw: 27,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 19,
            pitch: 192,
            yaw: 85,
            type: 'scene',
            sceneId: 11
          },
        ]
      },
      {
        id: 10,
        roomId: 5,
        yaw: 8,
        pitch: -5,
        horizonPitch: -6,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[1],
        imgPng: mainz.png.electricityMeter[1],
        house: null,
        hotSpots: [
          {
            id: 20,
            pitch: 197,
            yaw: 185,
            type: 'scene',
            sceneId: 4
          }
        ]
      },
      {
        id: 11,
        roomId: 5,
        yaw: 10,
        pitch: 0,
        horizonPitch: -5,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[2],
        imgPng: mainz.png.electricityMeter[2],
        house: null,
        hotSpots: [
          {
            id: 21,
            pitch: 205,
            yaw: 125,
            type: 'scene',
            sceneId: 10
          },
          {
            id: 22,
            pitch: 182,
            yaw: 228,
            type: 'scene',
            sceneId: 12
          },
        ]
      },
      {
        id: 12,
        roomId: 5,
        yaw: 0,
        pitch: -5,
        horizonPitch: -8,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.electricityMeter[3],
        imgPng: mainz.png.electricityMeter[3],
        house: null,
        hotSpots: [
          {
            id: 23,
            pitch: 176,
            yaw: 135,
            type: 'scene',
            sceneId: 11
          },
          {
            id: 24,
            pitch: 200,
            yaw: 115,
            type: 'scene',
            sceneId: 10
          }
        ]
      },
      {
        id: 13,
        name: ["images360.gasPipeRoom"],
        roomId: 6,
        yaw: 10,
        pitch: 20,
        horizonPitch: 20,
        horizonRoll: 0,
        hfov: 140,
        imgWebp: mainz.webp.gasPipe[0],
        imgPng:  mainz.png.gasPipe[0],
        house: null,
        hotSpots: [
          {
            id: 25,
            pitch: 180,
            yaw: 260,
            type: 'scene',
            sceneId: 1
          }
        ]
      }
    ]
  }
];


export const filterRule = [
  {
    name: 'heater',
    modbus: [200, 201, 212, 213, 232, 233],
  },
  {
    name: 'hot_water',
    modbus: [232, 233, 151],
  },
  {
    name: 'chilled_water',
    modbus: [101, 100],
  },
  {
    name: 'zirculation',
    modbus: [152, 160, 161, 162, 163, 164, 165, 166],
  },
];

export const temperatureRule = {
  '62061f10452e21129b8ca241': [
    {
      modbus: [160, 161, 162, 163, 164, 165, 166],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [100],
      rule: {
        red: [
          {temperature: [25, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [null, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [152],
      rule: {
        red: [
          {temperature: [null, 54.9], text: 'isLow'},
          {temperature: [60.1, null], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [151],
      rule: {
        red: [
          {temperature: [null, 59.9], text: 'isLow'},
          {temperature: [64.1, null], text: 'isHigh'},
        ],
        blue: [{temperature: [60, 64], text: 'optimal'}],
      },
    },
    {
      modbus: [101, 22],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [200, 201],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [232],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [212],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [213],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [233],
      rule: {
        blue: [ {temperature: [60,75], text: 'optimal'} ],
      },
    },
  ],
  '62061f10452e21129b8ca242': [
    {
      modbus: [160, 161, 162],
      rule: {
        red: [
          {temperature: [10, 54.9], text: 'isLow'},
          {temperature: [60.1, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [100],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [152],
      rule: {
        red: [
          {temperature: [10, 54.9], text: 'isLow'},
          {temperature: [60.1, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [232],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [233],
      rule: {
        blue: [ {temperature: [60,75], text: 'optimal'} ],
      },
    },
    {

      modbus: [201],
      rule: {
        blue: [ {temperature: [40,50], text: 'optimal'} ],
      },
    },
    {
      modbus: [200],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [212],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [213],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [151],
      rule: {
        red: [{temperature: [10, 60], text: 'isLow'}],
        blue: [ {temperature: [60.1, 66], text: 'optimal'} ],
      },
    },
  ],
  '62061f10452e21129b8ca243': [
    {
      modbus: [160, 161, 162],
      rule: {
        red: [
          {temperature: [10, 54.9], text: 'isLow'},
          {temperature: [60.1, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [100],
      rule: {
        red: [
          {temperature: [null, 5], text: 'isLow'},
          {temperature: [25, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [5.1, 24.9], text: 'optimal'} ],
      },
    },
    {
      modbus: [152],
      rule: {
        red: [
          {temperature: [10, 54.9], text: 'isLow'},
          {temperature: [60.1, 200], text: 'isHigh'},
        ],
        blue: [ {temperature: [55, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [232],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [233],
      rule: {
        blue: [ {temperature: [60,75], text: 'optimal'} ],
      },
    },
    {

      modbus: [201],
      rule: {
        blue: [ {temperature: [40,50], text: 'optimal'} ],
      },
    },
    {
      modbus: [200],
      rule: {
        blue: [ {temperature: [60, 75], text: 'optimal'} ],
      },
    },
    {
      modbus: [212],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [213],
      rule: {
        blue: [ {temperature: [40, 60], text: 'optimal'} ],
      },
    },
    {
      modbus: [151],
      rule: {
        red: [{temperature: [10, 60], text: 'isLow'}],
        blue: [ {temperature: [60.1, 66], text: 'optimal'} ],
      },
    },
  ],
};

export const exceptionsTemperatureRule = {
  '62061f10452e21129b8ca241': [100, 101, 22],
  '62061f10452e21129b8ca242': [],
  '62061f10452e21129b8ca243': [],
}

export const exceptionsTemperatureRuleNotModal = {
  '62061f10452e21129b8ca241': [100],
  '62061f10452e21129b8ca242': [],
  '62061f10452e21129b8ca243': [],
}

export const propertyImgRule = {
  '62061f10452e21129b8ca241': { imgWebp: buildings.webp.building_1_webp, imgPng: buildings.png.building_1_png },
  '62061f10452e21129b8ca242': { imgWebp: buildings.webp.building_2_webp, imgPng: buildings.png.building_2_png },
  '62061f10452e21129b8ca243': { imgWebp: buildings.webp.building_2_webp, imgPng: buildings.png.building_2_png },
};

export const houseDetailsRule = {
  1: {
    imgWebp: sensors_241_images_5.Kaltwassereingang.webp,
    imgPng: sensors_241_images_5.Kaltwassereingang.jpg,
  },
  2: {
    imgWebp: sensors_241_images_5.Rukspulfilter.webp,
    imgPng: sensors_241_images_5.Rukspulfilter.jpg,
  },
  3: {
    imgWebp: sensors_241_images_5.Warmwasserspeicher.webp,
    imgPng: sensors_241_images_5.Warmwasserspeicher.jpg,
  },
  4: {
    imgWebp: sensors_241_images_5.Heizkessel.webp,
    imgPng: sensors_241_images_5.Heizkessel.png,
  },
  5: {
    imgWebp: sensors_241_images_5.Speicherladepumpe.webp,
    imgPng: sensors_241_images_5.Speicherladepumpe.jpg,
  },
  6: {
    imgWebp: sensors_241_images_5.Heizkreispumpe.webp,
    imgPng: sensors_241_images_5.Heizkreispumpe.jpg,
  },
  7: {
    imgWebp: sensors_241_images_5.Zirkulationspumpe.webp,
    imgPng: sensors_241_images_5.Zirkulationspumpe.jpg,
  },
  8: {
    imgWebp: sensors_241_images_7.Kaltwassereingang.webp,
    imgPng: sensors_241_images_7.Kaltwassereingang.jpg,
  },
  9: {
    imgWebp: sensors_241_images_7.Rukspulfilter.webp,
    imgPng: sensors_241_images_7.Rukspulfilter.jpg,
  },
  10: {
    imgWebp: sensors_242_images.Kaltwassereingang.webp,
    imgPng: sensors_242_images.Kaltwassereingang.jpg,
  },
  11: {
    imgWebp: sensors_242_images.Rukspulfilter.webp,
    imgPng: sensors_242_images.Rukspulfilter.jpg,
  },
  12: {
    imgWebp: sensors_242_images.Warmwasserspeicher.webp,
    imgPng: sensors_242_images.Warmwasserspeicher.jpg,
  },
  13: {
    imgWebp: sensors_242_images.Heizkessel.webp,
    imgPng: sensors_242_images.Heizkessel.jpg,
  },
  14: {
    imgWebp: sensors_242_images.Speicherladepumpe.webp,
    imgPng: sensors_242_images.Speicherladepumpe.jpg,
  },
  15: {
    imgWebp: sensors_242_images.Heizkreispumpe.webp,
    imgPng: sensors_242_images.Heizkreispumpe.jpg,
  },
  16: {
    imgWebp: sensors_242_images.Zirkulationspumpe.webp,
    imgPng: sensors_242_images.Zirkulationspumpe.jpg,
  },
}

export const housesRule = {
  '62061f10452e21129b8ca241': {
    5: [
      {
        id: 1,
        textType: "ColdWaterInlet",
        imgWebp: sensors_241_5.webp.s_4,
        imgPng: sensors_241_5.png.s_4,
        modbus: 101,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 2,
        textType: "BackwashFilter",
        imgWebp: sensors_241_5.webp.s_1,
        imgPng: sensors_241_5.png.s_1,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Judo JRSF 1',
            NominalDiameter: 'DN32',
            PressureLoss: '0,2 bar',
            Flow: '14 m³/h',
          },
          tabs: ['info', 'washing_process']
        }
      },
      {
        id: 3,
        textType: 'StorageWaterHeater',
        imgWebp: sensors_241_5.webp.s_7,
        imgPng: sensors_241_5.png.s_7,
        modbus: 151,
        hasDetails: true,
        details: {
          info: {
            name: 'Ditech A104',
            StorageType: 'Speicherladesystem',
            MemoryContent: '395 L',
            HeatOutput: '150 kW',
            SerialNumber: '0248-13 MC/E'
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 4,
        textType: 'Boiler',
        imgWebp: sensors_241_5.webp.s_2,
        imgPng: sensors_241_5.png.s_2,
        modbus: { sensor_in: 200, sensor_out: 201},
        hasDetails: true,
        details: {
          info: {
            name: 'Buderus Logano G334XZ',
            HeatGenerators: 'Boiler',
            EnergySource: 'Gas',
            NominalHeatOutput: '98,2 kW',
            BoilerContents: '43 L',
            SerialNumber: '05621813-00-2253-01317',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 5,
        textType: 'StorageChargingPump',
        imgWebp: sensors_241_5.webp.s_6,
        imgPng: sensors_241_5.png.s_6,
        modbus: { sensor_in: 232, sensor_out: 233},
        hasDetails: true,
        details: {
          info: {
            name: 'Speck Inova NH 32/60',
            NominalDiameter: 'DN32',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '40W',
            HeadOfFunding: '60 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP X2D',
            SerialNumber: '4174114',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 6,
        textType: 'HeatingCircuitPump',
        imgWebp: sensors_241_5.webp.s_3,
        imgPng: sensors_241_5.png.s_3,
        modbus: { sensor_in: 212, sensor_out: 213},
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos Magna 3 F 220',

            NominalDiameter: 'DN40',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '348 W',
            HeadOfFunding: '100 mb',
            InstallationLength: '220 mm',
            SafetyClass: 'IP X4D',
            SerialNumber: '10016426',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 7,
        textType: 'CirculationPump',
        imgWebp: sensors_241_5.webp.s_8,
        imgPng: sensors_241_5.png.s_8,
        modbus: 152,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 20-30',
            NominalDiameter: 'DN20',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '75 W',
            HeadOfFunding: '30 mb',
            InstallationLength: '150 mm',
            SafetyClass: 'IP 44',
            SerialNumber: '59643500',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
    ],
    7: [
      {
        id: 8,
        textType: 'ColdWaterInlet',
        imgWebp: sensors_241_7.webp.s_9,
        imgPng: sensors_241_7.png.s_9,
        modbus: 100,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 9,
        textType: 'BackwashFilters',
        imgWebp: sensors_241_7.webp.s_5,
        imgPng: sensors_241_7.png.s_5,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Judo JRSF 1',
            NominalDiameter: 'DN32',
            PressureLoss: '0,2 bar',
            Flow: '14 m³/h',
          },
          tabs: ['info', 'washing_process']
        }
      },
    ],
  },
  '62061f10452e21129b8ca242': {
    null: [
      {
        id: 10,
        textType: 'ColdWaterInlet',
        imgWebp: sensors_242_null.webp.s_11,
        imgPng: sensors_242_null.png.s_11,
        modbus: 100,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 11,
        textType: 'BackwashFilter',
        imgWebp: sensors_242_null.webp.s_12,
        imgPng: sensors_242_null.png.s_12,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Honeywell HS10S-11/2aa',
            NominalDiameter: 'DN40',
            PressureLoss: '1,1 bar',
            Flow: '9,1 m³/h',
            SerialNumber: 'DW 9021AT2318',
          },
          tabs: ['info', 'washing_process']
        }
      },
      {
        id: 12,
        textType: 'StorageWaterHeater',
        imgWebp: sensors_242_null.webp.s_16,
        imgPng: sensors_242_null.png.s_16,
        modbus: 151,
        hasDetails: true,
        details: {
          info: {
            name: 'Viessmann Vitocell 100',
            StorageType: 'Speicherladesystem',
            MemoryContent: '300 L',
            HeatOutput: '33 kW',
            SerialNumber: '7265117100815107',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 13,
        textType: 'Boiler',
        imgWebp: sensors_242_null.webp.s_14,
        imgPng: sensors_242_null.png.s_14,
        modbus: { sensor_in: 200, sensor_out: 201 },
        hasDetails: true,
        details: {
          info: {
            name: 'Viessmann Vitocrossal 300',
            HeatGenerators: 'Heizkessel',
            EnergySource: 'Gas',
            NominalHeatOutput: '47,8 kW',
            BoilerContents: '98 L',
            SerialNumber: '7226078100332',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 14,
        textType: 'StorageChargingPump',
        imgWebp: sensors_242_null.webp.s_15,
        imgPng: sensors_242_null.png.s_15,
        modbus: { sensor_in: 232, sensor_out: 233 },
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 25-40',
            NominalDiameter: 'DN25',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '60 W',
            HeadOfFunding: '40 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP 44',
            SerialNumber: '59544123',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 15,
        textType: 'HeatingCircuitPump',
        imgWebp: sensors_242_null.webp.s_10,
        imgPng: sensors_242_null.png.s_10,
        modbus: { sensor_in: 212, sensor_out: 213 },
        hasDetails: true,
        details: {
          info: {
            name: 'Speck Inova NH 25/60',
            NominalDiameter: 'DN25',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '40 W',
            HeadOfFunding: '60 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP X4D',
            SerialNumber: '4150195',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 16,
        textType: 'CirculationPump',
        imgWebp: sensors_242_null.webp.s_13,
        imgPng: sensors_242_null.png.s_13,
        modbus: 152,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 15-14B',
            NominalDiameter: 'DN15',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '25 W',
            HeadOfFunding: '14 mb',
            InstallationLength: '80 mm',
            SafetyClass: 'IP 42',
            SerialNumber: '96433883',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
    ]
  },
  '62061f10452e21129b8ca243': {
    null: [
      {
        id: 10,
        textType: 'ColdWaterInlet',
        imgWebp: sensors_242_null.webp.s_11,
        imgPng: sensors_242_null.png.s_11,
        modbus: 100,
        hasDetails: true,
        details: {
          tabs: ['images', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 11,
        textType: 'BackwashFilter',
        imgWebp: sensors_242_null.webp.s_12,
        imgPng: sensors_242_null.png.s_12,
        modbus: null,
        hasDetails: true,
        details: {
          info: {
            name: 'Honeywell HS10S-11/2aa',
            NominalDiameter: 'DN40',
            PressureLoss: '1,1 bar',
            Flow: '9,1 m³/h',
            SerialNumber: 'DW 9021AT2318',
          },
          tabs: ['info', 'washing_process']
        }
      },
      {
        id: 12,
        textType: 'StorageWaterHeater',
        imgWebp: sensors_242_null.webp.s_16,
        imgPng: sensors_242_null.png.s_16,
        modbus: 151,
        hasDetails: true,
        details: {
          info: {
            name: 'Viessmann Vitocell 100',
            StorageType: 'Speicherladesystem',
            MemoryContent: '300 L',
            HeatOutput: '33 kW',
            SerialNumber: '7265117100815107',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 13,
        textType: 'Boiler',
        imgWebp: sensors_242_null.webp.s_14,
        imgPng: sensors_242_null.png.s_14,
        modbus: { sensor_in: 200, sensor_out: 201 },
        hasDetails: true,
        details: {
          info: {
            name: 'Viessmann Vitocrossal 300',
            HeatGenerators: 'Heizkessel',
            EnergySource: 'Gas',
            NominalHeatOutput: '47,8 kW',
            BoilerContents: '98 L',
            SerialNumber: '7226078100332',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 14,
        textType: 'StorageChargingPump',
        imgWebp: sensors_242_null.webp.s_15,
        imgPng: sensors_242_null.png.s_15,
        modbus: { sensor_in: 232, sensor_out: 233 },
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 25-40',
            NominalDiameter: 'DN25',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '60 W',
            HeadOfFunding: '40 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP 44',
            SerialNumber: '59544123',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 15,
        textType: 'HeatingCircuitPump',
        imgWebp: sensors_242_null.webp.s_10,
        imgPng: sensors_242_null.png.s_10,
        modbus: { sensor_in: 212, sensor_out: 213 },
        hasDetails: true,
        details: {
          info: {
            name: 'Speck Inova NH 25/60',
            NominalDiameter: 'DN25',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '40 W',
            HeadOfFunding: '60 mb',
            InstallationLength: '180 mm',
            SafetyClass: 'IP X4D',
            SerialNumber: '4150195',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
      {
        id: 16,
        textType: 'CirculationPump',
        imgWebp: sensors_242_null.webp.s_13,
        imgPng: sensors_242_null.png.s_13,
        modbus: 152,
        hasDetails: true,
        details: {
          info: {
            name: 'Grundfos UP 15-14B',
            NominalDiameter: 'DN15',
            RotationalSpeed: '50 Hz',
            CurrentConsumption: '25 W',
            HeadOfFunding: '14 mb',
            InstallationLength: '80 mm',
            SafetyClass: 'IP 42',
            SerialNumber: '96433883',
          },
          tabs: ['info', 'live_date_paul', 'live_date_techem']
        }
      },
    ]
  }
};

export const PipesDetailsSettings = {
  header: 'circulation_pump_group',
  modbus: {
    pipes: [160, 161, 162, 163, 164, 165, 166],
    returnFlow: [152],
    storageTank: [232]
  },
  styles: {
    pipes: {
      color: '#6479B7',
      strokeDasharray: '0'
    },
    returnFlow: {
      color: '#FF797E',
      strokeDasharray: '0'
    },
    storageTank: {
      color: '#3671E9',
      strokeDasharray: '5, 3'
    }
  }
}

export const defaultPoints = [
  {
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: "62061f10452e21129b8ca241",
      category: "property"
    },
    geometry: {
      type: "Point",
      coordinates: [8.124576182035836, 50.02926842822507]
    }
  },
  { 
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: "62061f10452e21129b8ca242",
      category: "property"
    },
    geometry: {
      type: "Point",
      coordinates: [8.285063970425599, 50.00791170829136]
    }
  },
  {
    type: "Feature",
    properties: {
      cluster: false,
      crimeId: "62061f10452e21129b8ca243",
      category: "property"
    },
    geometry: {
      type: "Point",
      coordinates: [8.502410, 49.477668]
    }
  }
]

export const languages = ['de', "en"]

export const disabledRedZone = {
  '62061f10452e21129b8ca241': [200, 212, 213, 233],
  '62061f10452e21129b8ca242': [200, 201, 212, 213],
  '62061f10452e21129b8ca243': [200, 201, 212, 213],
}

export const modbusType = {
  '62061f10452e21129b8ca241': ['Techem', 'PAUL'],
  '62061f10452e21129b8ca242': ['Techem', 'PAUL'],
  '62061f10452e21129b8ca243': ['Techem'],
}

export const Roles = {
  USER: "User",
  COMPANY_ADMIN: "Main User",
  SUPER_ADMIN: "Super Admin",
}

export const UserStatus = {
  1: "Pending",
  2: "Active",
  3: "Disabled"
}