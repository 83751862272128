import React from 'react';
import styles from "../../styles/components/Sencors/FlushingProcessForFilters.module.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { GreyButton } from "../Inputs/index";
import {useTranslation} from "react-i18next";
const minuteSeconds = 60;

const timerProps = {
  isPlaying: true,
  size: 117,
  strokeWidth: 4.8
};

const renderTime = (time, name, t) => {
  return (
    <div className={styles.timeContainer}>
      <span className={styles.timeTitle}>{time} min</span>
      <span className={styles.timeSubtitle}>{t(`${name}.remainingTime`)}</span>
    </div>
  );
};

const getTimeMinutes = (time) => Math.ceil(time / minuteSeconds);

const FlushingProcessForFilters = props => {
  const { isModal = false, updateFlushingProcess } = props;
  const name = 'FlushingProcess';
  const { t } = useTranslation();

  const updateProcess = (data) => {
    updateFlushingProcess(data);
  }

  return (
    <div className={`flex-column ${styles.container} ${isModal ? styles.modal_container : ''}`}>
      <CountdownCircleTimer
        {...timerProps}
        colors={[ 
            ["#0000FF", 1/15],
            ["#1CE783", 14/15],
        ]}
        isLinearGradient={true}
        duration={300}
        initialRemainingTime={600}
        trailColor={[["#dbdbdb"]]}
        >
          {({ elapsedTime }) =>
            renderTime(getTimeMinutes(Math.abs(elapsedTime)), name, t)
          }
      </CountdownCircleTimer>
      <GreyButton text={t(`${name}.buttonStopFlashingText`)} isModal={isModal} onClick={updateProcess}/>
    </div>
  )
};

export default FlushingProcessForFilters;