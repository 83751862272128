import React from 'react';
import {FreeMode, Navigation} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SensorCard } from "../index";

const SensorLayoutGrid = props => {
  const { cards = [], filter = {} } = props;
  return (
    <Swiper freeMode modules={[FreeMode, Navigation]} slidesPerView={'auto'} spaceBetween={40} navigation grabCursor >
      {cards.length
          ? cards.map(c => <SwiperSlide key={c.modbus}><SensorCard card={c} warningFilter={filter.display_warnings}/></SwiperSlide>)
          // : <EmptyCard />
          : null
      }
    </Swiper>
  )
};

export default SensorLayoutGrid;
