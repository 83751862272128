import React from 'react';
import styles from '../../styles/components/Sencors/FlushingProcess.module.css';
import flushingLoadingPNG from "../../icons/loading_indicator.png";
import { GreyButton } from "../Inputs/index";
import {useTranslation} from "react-i18next";

const FlushingProcessForPipes = props => {
  const {updateFlushingProcess, temperature} = props;
  const name = 'FlushingProcess';
  const { t } = useTranslation();

  const updateProcess = (data) => {
    updateFlushingProcess(data);
  }

  return (
    <div className={`flex-column ${styles.container}`}>
        <div className={`flex-row ${styles.temperatureContainer}`}>
          <p>{temperature.toFixed(1)}</p>
        </div>
        <img src={flushingLoadingPNG} alt="" className={styles.flushLoading}/>
        <p className={styles.flushingTitle}>{t(`${name}.flushProcess`)}..</p>
        <GreyButton text={t(`${name}.buttonStopFlashingText`)} isModal onClick={updateProcess}/>
    </div>
  )
};

export default FlushingProcessForPipes;
