import {devicesRule, exceptionsTemperatureRule, modbusName, temperatureRule} from "./constants";
import moment from "moment";

export const SomeValue = (obj, sFunc) => Object.keys(obj).some(sFunc);

export const setToLocalStorage = (name, value) => localStorage.setItem(name, JSON.stringify(value));
export const getFromLocalStorage = name =>
    JSON.parse(localStorage.getItem(name) !== 'undefined' ? localStorage.getItem(name) : 'null');

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const mobileSize = window.innerWidth < 992;


export const getTypeSensor = s => devicesRule.find(d => d.device_uid === s.device_uid)?.device_type;

export const getNameSensor = (s = {}, t, _id = null) => {
  const modbusRule = modbusName[s.modbus];
  const id = _id || s.property;
  return {
    component: modbusRule && modbusRule.componentType ? t(`Sensors.component.${modbusRule.componentType}`) : modbusRule.component,
    name: modbusRule && id ? modbusRule.localization[id]?.nameType ? t(`Sensors.name.${modbusRule.localization[id].nameType}`) : modbusRule.localization[id]?.name : ''
  };
}

export const isEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
export const isEmptyCompany = obj => obj && obj.properties?.length === 0 && obj.users?.length === 0 && Object.keys(obj).length === 2;


export const getStatusSensor = (sensor = {}, property_id = null) => {
  const { modbus, temperature, updated_at } = sensor;
  const ruleArr = temperatureRule[property_id];
  const ruleForSensor = ruleArr ? ruleArr.find(r => r.modbus.includes(modbus)) : {};

  if (ruleForSensor && ruleForSensor.rule) {
    const { rule } = ruleForSensor;
    let title = '';
    const index = Object.values(rule).findIndex(t => t.find(item => {
      title = item.text;
      const { temperature: t } = item;
      return temperature > t[0] && temperature < t[1];
    }));
    const statusName = index === -1 ? 'red' : Object.keys(rule)[index];
    const statusText = index === -1 ? 'isLow' : title;

    const status = !(isEmpty(sensor)
    || (moment.duration(moment().diff(updated_at))).asHours() >= 1
    || statusName === 'red'
    || statusName !== 'blue'
    || temperature > 100
    || temperature < 0);

    return { status, statusText }
  } else return {};
};

export const getStatus = (sensors = [], property_id) => {
  const { modbus = null } = sensors[0] || {};
  const lengthRule = exceptionsTemperatureRule[property_id]?.includes(modbus) ? 0 : 1;
  return sensors.length > lengthRule && sensors.filter(s => getStatusSensor(s, property_id).status).length > lengthRule;
}

export const checkWebPSupport = async () => {
  return new Promise(res => {
    const webP = new Image();
    webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    webP.onload = webP.onerror = () => {
        res(webP.height === 2);
    };        
  })
}

export const diff = (a1= [], a2 = []) => a1.filter(i=>!a2.includes(i)).concat(a2.filter(i=>!a1.includes(i)))

export const getKeys = obj => Object.keys(obj);
export const getKeyByValue = (object, value) => getKeys(object).find(key => object[key] === value);

export const getDateFormat = (date, format) => moment(moment(date).format()).format(format || 'DD.MM.YYYY');
// export const getStartDay = d => moment(d).set({hour:0,minute:0,second:0,millisecond:0});
// export const getStartDay = d => moment(d).subtract(1, 'day').endOf('day');
export const getStartDay = d => moment(d).startOf('day');
export const getStartMonth = d => moment(d).startOf('month');
// export const getEndDay = d => getStartDay(moment(d).add(1, 'day'));
// export const getEndDay = d => moment(d).set({hour:23,minute:59,second:59,millisecond:59});
export const getEndDay = d => moment(d).endOf('day');
export const getEndMonth = d => moment(d).endOf('month');

export const getFromDay = d => moment(d).subtract(1, 'day');
export const getFrom7Day = d => moment(d).subtract(6, 'days');
export const getFrom16Day = d => moment(d).subtract(16, 'days');
export const getFromMonth = d => moment(d).subtract(1, 'month');
export const getFromYear = d => moment(d).subtract(365, 'days');
export const getFromMax = () => moment('01-01-2022');

export const getFromStartMonth = d => moment(d).startOf('month');
export const getFromStartYear = d => moment(d).startOf('year');

export const getToDay = d => moment(d).add(1, 'day');
export const getTo7Day = d => moment(d).add(6, 'days');
export const getTo14Day = d => moment(d).add(13, 'days');
export const getToMonth = d => moment(d).add(1, 'month');
export const getToYear = d => moment(d).add(365, 'days');
export const isSameCurrentDateByDay = d => moment().isSame(moment(d), 'day')

export const wrapInArray = v => typeof v === 'number' ? [v] : typeof v === 'object' ? Object.values(v) : v

export const stringToColor = (string = '') => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value ? `00${value.toString(16)}`.slice(-2) : '#bdbdbd';
  }
  /* eslint-enable no-bitwise */
  return color;
}

export const stringAvatar = (name = '') => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0] || 'A'}${name.split(' ')[1]  ? name.split(' ')[1][0] : ''}`,
  };
}

export const getRandomID = () => Math.random();