import React, {useState} from 'react';
import {Tab} from "bootstrap";
import {Tabs} from "react-bootstrap";
import {PipesChartWrap} from "../index";
import {useTranslation} from "react-i18next";
import {getFrom16Day} from "../../utils/functions";
import {useSelector} from "react-redux";
import {getDemoVersion} from "../../redux/selectors";
import moment from "moment";
import {useParams} from "react-router";
import {modbusType} from "../../utils/constants";

const PipesDetails = props => {
    const { id: propertyId } = useParams();
    const name = 'PipesDetails';
    const { card } = props;
    const { tabs = [], header = '' } = card || {};

    const [hoverTypeLine, setHoverTypeLine] = useState();
    const [checkedLegend, setCheckedLegend] = useState(
        ['pipes', 'returnFlow', 'storageTank', 'minTemperature', 'anomalies']
    )
    const [dateType, setDateType] = useState('month');
    const [from, setFrom] = useState(getFrom16Day());
    const [to, setTo] = useState(moment());
    const [rangeType, setRangeType] = useState(1);

    const { t } = useTranslation();
    const [tabType, setTabType] = useState(tabs[0] || 'Techem')

    const demoVersion = useSelector(getDemoVersion);

    const tabsType = demoVersion
        ? [{type: 'Techem', title: 'Techem'}]
        : [{type: 'Techem', title: 'Techem'}, {type: 'PAUL', title: 'PAUL'}];

    return (
        <div>
            <h2 className="mb-50">{t(`${name}.title`)}</h2>
            <div className={'container-box-shadow bg-color-main-color-form-bg px-25 pt-15 pb-40'}>
                <p className={'container-header'} style={{margin: demoVersion ? 0 : '0 0 1-10px'}}>{t(`${name}.${header}`)}</p>
                <Tabs
                    transition={false}
                    activeKey={tabType}
                    onSelect={setTabType}
                    className={`tabs mb-3 ${demoVersion ? 'disable_tab' : ''}`}
                >
                    {tabsType.map(tab => (
                        <Tab eventKey={tab.type} title={tab.title} key={tab.type}>
                            <PipesChartWrap
                                card={card}
                                type={tab.type}
                                hoverTypeLine={hoverTypeLine}
                                checkedLegend={checkedLegend}
                                setHoverTypeLine={setHoverTypeLine}
                                setCheckedLegend={setCheckedLegend}

                                dateType={dateType}
                                from={from}
                                to={to}
                                rangeType={rangeType}
                                setDateType={setDateType}
                                setFrom={setFrom}
                                setTo={setTo}
                                setRangeType={setRangeType}
                            />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </div>
    )
}

export default PipesDetails;