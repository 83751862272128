import React, {useState, useEffect, useRef} from 'react';
import styles from "../../styles/components/Sencors/SensorCardForStartFlushing.module.css";
import AlarmIconSVG from "../../icons/alarm.svg";
import HistoryIconSVG from "../../icons/history.svg";
import {housesRule} from "../../utils/constants";
import { useParams } from 'react-router';
import {FlushingProcessForFilters, DetailsCard} from "../index";
import { PrimaryButton, IconButton } from "../Inputs/index";
import { CSSTransition } from 'react-transition-group';
import {useTranslation} from "react-i18next";
import moment from "moment";

import '../../styles/components/Inputs/Button.css';
import "../../styles/components/Sencors/TransitionGroup.css";

const StartFlushingForFilters = props => {
  const name = 'FlushingProcess';
  const { isModal = false, title } = props;
  const [flushProcess, setFlushProcess] = useState(false);
  const [showFlushingInfo, setShowFlushingInfo] = useState(true);
  const [detailsCard, setdetailsCard] = useState(false);
  const [houseCards, setHouseCards] = useState([]);
  const { id: _id } = useParams();
  const { t } = useTranslation();
  let flushTimer = useRef(null);
  const id = _id.split('&')[0];
  const card = houseCards ? Object.keys(houseCards) : [];

  const handleCloseDetails = () => setdetailsCard(false);

  useEffect(() => {
    setHouseCards(housesRule[id])
  }, [id]);

  useEffect(() => {
    if(flushProcess) {
      flushTimer.current = setTimeout(() => {
        setFlushProcess(false);
      }, 300000);
    } else {
      clearTimeout(flushTimer.current);
    }
  }, [flushProcess]);

  const updateFlushProcess = (data) => {
    setFlushProcess(data);
  }

  const showDetailsCard = (data) => {
    setdetailsCard(data);
  }

  return (
    <div className={`flex-column ${styles.container} ${isModal ? styles.modal_container : ''}`}>
      {
        title && (
          <p className={styles.title}>{title}</p>
        )
      }
      {
        showFlushingInfo && (
          <>
            <span className={styles.header}>{t(`${name}.sensorCardTitle`)}</span>
            <div className={styles.status_line}></div>
            <span>{moment().format("D. MMMM YYYY")}</span>
            <span>08:00 Uhr</span>
            <div className={`flex-row ${styles.timeContainer}`}>
              <img src={AlarmIconSVG} alt="Edit img" className={styles.alarmIcon}/>
              <span>5 min</span>
            </div>
            <div className={`flex-row ${styles.buttonsContainer}`}>
              {
                isModal && ( 
                  <IconButton onClick={showDetailsCard}>
                    <img src={HistoryIconSVG} alt="history icon" className={styles.historyIcon}/>
                  </IconButton>
                )
              }
              <PrimaryButton text={t(`${name}.buttonText`)} isModal={isModal} onClick={updateFlushProcess}/>
            </div>
          </>
        )
      }
      <CSSTransition
        in={flushProcess}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowFlushingInfo(false)}
        onExited={() => setShowFlushingInfo(true)}
      >
        <FlushingProcessForFilters updateFlushingProcess={updateFlushProcess} isModal={isModal}/>
      </CSSTransition>

      {
        detailsCard && (
          <DetailsCard handleCloseDetails={handleCloseDetails} open={detailsCard} card={houseCards[card][1]}/>
        )
      }
    </div>
  )
};

export default StartFlushingForFilters;