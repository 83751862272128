import React, {useCallback, useEffect, useRef, useState, useLayoutEffect} from 'react';
import "pannellum";
import {useSelector} from "react-redux";
import {getImgSupport} from "../../redux/selectors";
import {isEmpty} from "../../utils/functions";
import "pannellum/build/pannellum.css";

import "../../styles/components/Img360/Pannellum.css";

const PannellumWrap = props => {
    const {
        onClickHotspot,
        scenes = [],
        autoLoad = true,
        activeSceneId = null,
        children,
        openFull
    } = props;

    const { current: pannellumId } = useRef(`pannellum_${Math.random().toString(36).substr(2, 9)}`)
    const viewer = useRef(null);
    const [sceneById, setSceneById] = useState({});
    const imgSupport = useSelector(getImgSupport);

    const hotspot = useCallback((hotSpotDiv, spot) => {
        hotSpotDiv.onclick = e => {
            onClickHotspot(spot);
            hotSpotDiv.ontouchend(e)
        }
        hotSpotDiv.setAttribute("id", "textInfo");
        const hDiv = document.createElement('div');
        hDiv.classList.add('hotspot');
        const outDiv = document.createElement('div');
        outDiv.classList.add('out');
        const inDiv = document.createElement('div');
        inDiv.classList.add('in');
        const imageDiv = document.createElement('div');
        imageDiv.classList.add('image');
        hotSpotDiv.appendChild(hDiv);
        hDiv.appendChild(inDiv);
        hDiv.appendChild(outDiv);
    }, [onClickHotspot])

    useEffect(() => {
        viewer.current?.toggleFullscreen()
    }, [openFull])

    useEffect(() => {
        setSceneById(scenes.reduce((acc, scene) => (
            { ...acc,
                [scene.id]: {
                    ...scene,
                    panorama: imgSupport ? scene.imgWebp : scene.imgPng,
                    hotSpots: [
                        ...(scene.hotSpots || []).map(spot => ({
                            ...spot,
                            createTooltipFunc: (hotSpotDiv) => hotspot(hotSpotDiv, spot),
                            onClick: (e) => onClickHotspot(e, spot)
                        })),
                    ],
                }
            }), {}))
    }, [scenes, onClickHotspot, imgSupport])

    useLayoutEffect(() => {
        if (document.querySelector(`#${pannellumId}`) && !isEmpty(sceneById)) {
            viewer.current = window.pannellum.viewer(pannellumId, {
                default: {
                    sceneFadeDuration: 500,
                    autoLoad,
                },
                scenes: sceneById,
                showControls: false
            });
        }
        return () => {
            viewer.current?.destroy();
        }
    }, [sceneById, pannellumId])


    useEffect(() => {
        if (viewer?.current && activeSceneId && sceneById[activeSceneId])
            viewer?.current?.loadScene(activeSceneId)
    }, [activeSceneId, sceneById, pannellumId])

    return (
        <div id={pannellumId} style={{ width: "100%", height: "100%" }}>
            {children}
        </div>
    )
}

export default PannellumWrap;