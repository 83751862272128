import React from 'react';
import Button from '@mui/material/Button';

import '../../styles/components/Inputs/Button.css';

const IconButton = props => {
  const {children, onClick} = props; 
  
  return (
    <Button style={{width: '30px', height: '30px'}} className={'iconButton'} variant="contained" onClick={() => onClick(true)}>
      {children}
    </Button>
  )
};
export default IconButton;